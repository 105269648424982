import { getDeliveryRecordsById } from "functions/Delivery";
import { swapObjectInArray } from "functions/Utility";
import { createContext, useContext, useState, useEffect } from "react";

const DeliveryBasketContext = createContext();

export default function DeliveryBasketContextProvider({ children }) {
    const [deliveryRecordGlobalList, setDeliveryRecordGlobalList] = useState([]);
    const [deliveryRecordGlobalListCount, setDeliveryRecordGlobalListCount] = useState(deliveryRecordGlobalList.length);

    useEffect(() => {
        setDeliveryRecordGlobalListCount(deliveryRecordGlobalList.length);
    }, [deliveryRecordGlobalList]);

    // Check existence
    const isThisDeliveryRecordExist = (deliveryRecordToCheck) => {
        // true: exist, false: do not exist
        if (!deliveryRecordToCheck) {
            // deliveryRecordToCheck is empty
            return false;
        }
        if (Object.keys(deliveryRecordToCheck).length <= 0) {
            // deliveryRecordToCheck is empty
            return false;
        }


        if (deliveryRecordGlobalList.length <= 0) {
            // no record
            return false;
        } else {
            try {
                // There are some records inside
                let deliveryRecordGlobalListCopy = deliveryRecordGlobalList;
                let foundResult = deliveryRecordGlobalListCopy.find((item) => { return item.deliveryID === deliveryRecordToCheck.deliveryID });
                if (foundResult) {
                    // there is already this record inside the list
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }

        }
    }

    // return the list with a key isExistInGlobalList
    const returnCheckDeliveryRecordExistList = (deliveryRecordListToCheck) => {
        // return an list of delivery record with 
        // [{other data: XXX, isExistInGlobalList: true/false}, {DR2}, .... , {DRn}]

        // Pre data check
        if (!deliveryRecordListToCheck || !Array.isArray(deliveryRecordListToCheck)) {
            return [];
        }

        // Case 1
        if (deliveryRecordListToCheck.length <= 0) {
            // no record
            return [];
        }

        if (deliveryRecordGlobalList.length <= 0) {
            // no record in global list
            // just return all false list
            deliveryRecordListToCheck.forEach((obj) => obj.isExistInGlobalList = false);
            return deliveryRecordListToCheck;
        } else {
            // There are records in both list
            deliveryRecordListToCheck.forEach((obj) => {
                try {
                    obj.isExistInGlobalList = (deliveryRecordGlobalList.findIndex((item) => { return (item.deliveryID === obj.deliveryID) }) != -1);
                } catch (error) {
                    obj.isExistInGlobalList = false;
                }
            }
            );
            return deliveryRecordListToCheck;
        }
    }

    // refresh single record drom db
    const refreshSingleRecordFromDBInList = async (deliveryIDToRefresh) => {
        if (deliveryRecordGlobalList.length <= 0) {
            // do nothing
            return;
        }

        let listToWork = deliveryRecordGlobalList;
        const indexToFind = listToWork.findIndex((item) => { return (item.deliveryID === deliveryIDToRefresh) });

        if (indexToFind == -1) {
            // Cannot find this deliveryID
            return;
        } else {
            listToWork[indexToFind] = await getDeliveryRecordsById(deliveryIDToRefresh);
        }

        setDeliveryRecordGlobalList([...listToWork]);
    }

    // refresh all record drom db
    const refreshAllRecordFromDBInList = async () => {
        if (deliveryRecordGlobalList.length <= 0) {
            // do nothing
            return;
        }

        let originalList = deliveryRecordGlobalList;
        let newList = [];


        for (const item of originalList) {
            // asynchronous operation 
            const result = await getDeliveryRecordsById(item.deliveryID);
            // results
            newList.push(result);
        }

        setDeliveryRecordGlobalList([...newList]);
    }

    // Append single record into the list
    const addSingleDeliveryRecordToGlobalList = (deliveryRecordToAdd, allowRepeatDeliveryID = false) => {
        if (deliveryRecordGlobalList.length <= 0) {
            // Just add the record 
            setDeliveryRecordGlobalList([deliveryRecordToAdd]);
        } else {
            // There are some records inside
            let deliveryRecordGlobalListCopy = deliveryRecordGlobalList;
            let foundResult = deliveryRecordGlobalListCopy.find((item) => { return item.deliveryID === deliveryRecordToAdd.deliveryID });
            if (foundResult) {
                // there is already this record inside the list
                if (allowRepeatDeliveryID) {
                    // Add 
                    setDeliveryRecordGlobalList([...deliveryRecordGlobalList, deliveryRecordToAdd]);
                } else {
                    // Do not add
                }
            } else {
                // Cannot find this record inside the list
                // Add 
                setDeliveryRecordGlobalList([...deliveryRecordGlobalList, deliveryRecordToAdd]);
            }
        }
    }

    // delete single record into the list
    const deleteSingleDeliveryRecordToGlobalList = (deliveryRecordToDelete) => {
        if (deliveryRecordGlobalList.length <= 0) {
            // Do nothing
        } else {
            // There are some records inside
            let deliveryRecordGlobalListCopy = deliveryRecordGlobalList;
            let filteredResult = deliveryRecordGlobalListCopy.filter((item) => {
                return item.deliveryID !== deliveryRecordToDelete.deliveryID
            });
            setDeliveryRecordGlobalList([...filteredResult]);
        }
    }

    // delete all records into the list
    const deleteAllDeliveryRecordToGlobalList = () => {
        setDeliveryRecordGlobalList([]);
    }
    // move up the item
    const moveUpSingleDeliveryRecordInGlobalList = (deliveryRecordToMove, toTheFront = false) => {
        if (deliveryRecordGlobalList.length <= 0) {
            // Do nothing and return 
            return;
        } else if (deliveryRecordGlobalList.length == 1) {
            // Only one element
            return;
        } else {
            // There are some records inside
            let deliveryRecordGlobalListToWork = deliveryRecordGlobalList;
            let foundIndex = deliveryRecordGlobalListToWork.findIndex((item) => { return item.deliveryID === deliveryRecordToMove.deliveryID });
            if (foundIndex != -1) {
                // there is already this record inside the list
                if (foundIndex == 0) {
                    // Already at the front
                    return;
                }
                if (toTheFront) {
                    // Move to the front
                    deliveryRecordGlobalListToWork = deliveryRecordGlobalListToWork.filter(item => item.deliveryID !== deliveryRecordToMove.deliveryID);
                    deliveryRecordGlobalListToWork.unshift(deliveryRecordToMove);
                } else {
                    // move up by one
                    swapObjectInArray(deliveryRecordGlobalListToWork, foundIndex, foundIndex - 1);
                }
                setDeliveryRecordGlobalList([...deliveryRecordGlobalListToWork]);
            } else {
                // Cannot find this record inside the list
                // Do nothing and return 
                return;
            }
        }
    }

    // move down the item
    const moveDownSingleDeliveryRecordInGlobalList = (deliveryRecordToMove, toTheLast = false) => {
        if (deliveryRecordGlobalList.length <= 0) {
            // Do nothing and return 
            return;
        } else if (deliveryRecordGlobalList.length == 1) {
            // Only one element
            return;
        } else {
            // There are some records inside
            let deliveryRecordGlobalListToWork = deliveryRecordGlobalList;
            let foundIndex = deliveryRecordGlobalListToWork.findIndex((item) => { return item.deliveryID === deliveryRecordToMove.deliveryID });
            let arrayLastIndex = deliveryRecordGlobalListToWork.length - 1;
            if (foundIndex != -1) {
                // there is already this record inside the list
                if (foundIndex == arrayLastIndex) {
                    // Already at the last
                    return;
                }
                if (toTheLast) {
                    // Move to the last
                    deliveryRecordGlobalListToWork = deliveryRecordGlobalListToWork.filter(item => item.deliveryID !== deliveryRecordToMove.deliveryID);
                    deliveryRecordGlobalListToWork.push(deliveryRecordToMove);
                } else {
                    // move down by one
                    swapObjectInArray(deliveryRecordGlobalListToWork, foundIndex, foundIndex + 1);
                }
                setDeliveryRecordGlobalList([...deliveryRecordGlobalListToWork]);
            } else {
                // Cannot find this record inside the list
                // Do nothing and return 
                return;
            }
        }
    }


    return (
        <DeliveryBasketContext.Provider
            value={{
                deliveryRecordGlobalList,
                setDeliveryRecordGlobalList,
                deliveryRecordGlobalListCount,
                addSingleDeliveryRecordToGlobalList,
                deleteSingleDeliveryRecordToGlobalList,
                isThisDeliveryRecordExist,
                returnCheckDeliveryRecordExistList,
                refreshSingleRecordFromDBInList,
                refreshAllRecordFromDBInList,
                moveUpSingleDeliveryRecordInGlobalList,
                moveDownSingleDeliveryRecordInGlobalList,
                deleteAllDeliveryRecordToGlobalList
            }}
        >
            {children}
        </DeliveryBasketContext.Provider>
    )

}

export const DeliveryBasketListGlobal = () => {
    return useContext(DeliveryBasketContext);
}

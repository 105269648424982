import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Dropdown,
    PopoverBody,
    UncontrolledPopover,
    CardFooter,
    ButtonGroup,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import QRCode from "react-qr-code";
import classnames from "classnames";
import { Trans, useTranslation } from "react-i18next";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Dropzone from "dropzone";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InvoiceCard from "../invoice/InvoiceCard";
import { getCustomerByID, getPhoneType } from "functions/Customer";
import { getDriverSelect2 } from "functions/Driver";
import { getCarSelect2 } from "functions/Car";
import { addDeliveryAddress } from "functions/DeliveryAddress";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import CustomerActionModal from "../customer/CustomerActionModal";
import PhotoUploadArea from "../components/PhotoUploadArea";
import { getDeliveryRecordsById } from "functions/Delivery";
import Notifications from "../components/Notifications";
import { updateDeliveryFull, addDeliverySignature, deleteDeliverySignature } from "functions/Delivery";
import DeliveryRecordAddProductModal from ".//DeliveryRecordAddProductModal";
import DeliveryRecordEditProductModal from "./DeliveryRecordEditProductModal";
import { getDeliveryStatusArray } from "functions/Delivery";
import DeliveryRecordSignatureModal from "../components/DeliveryRecordSignatureModal";
import { getImageType, convertFileToBase64, resizeFile, getBase64PhotoHeadRemoved } from "functions/Utility";
import { getDeliverySignature } from "functions/Delivery";
import { combineAddress } from "functions/DeliveryAddress";

import { getDeliveryPhoto } from "functions/Delivery";
import { addDeliveryPhoto, updateDeliveryPhotoMetaData } from "functions/Delivery";
import { deleteDeliveryPhoto } from "functions/Delivery";
import { updateAddress } from "functions/DeliveryAddress";
import { getSingleInvoice } from "functions/Invoice";
import { getUserId, getNickname } from "functions/Auth";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import InvoiceEditModal from "../invoice/InvoiceEditModal";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";
import DeliverySubitemTable from "../components/DeliverySubitemTable";
import DeliveryRecordSignature from "../components/DeliveryRecordSignature";
import CustomerDisplayCard from "../customer/CustomerDisplayCard";
import DeliveryBadge from "../components/DeliveryBadge";
import InvoiceBadge from "../components/InvoiceBadge";

import moment from "moment";
import { isFileImage } from "functions/Utility";
import DropdownControl from "../components/DropdownControl";
import { isAccessible } from "functions/SystemUserAccess";
import DeliveryAddressInputFieldWithMap from "../components/DeliveryAddressInputFieldWithMap";
import DeliveryAddressSelectInput from "../components/DeliveryAddressSelectInput";
import SpinnerOverlay from "../components/SpinnerOverlay";

import { deleteDeliveryRecord } from "functions/Delivery.js";
import DeliveryTruckWorkerListButtonDropdownGroup from "../components/DeliveryTruckWorkerListButtonDropdownGroup";
import DeliveryRecordMessageModal from "./DeliveryRecordMessageModal";
import DeliveryRecordCreateModal from "./DeliveryRecordCreateModal";
import { openPDFLink } from "functions/Utility";
import AddressModalEdit from "../address/AddressModalEdit";
import { getDeliveryRecordsByAddressID } from "functions/Delivery";
import AddressModalCreate from "../address/AddressModalCreate";
import { uuid4 } from "@sentry/utils";
import { updateDeliveryRecordCarID, updateDeliveryRecordDriverID, updateDeliveryRecordDeliveryStatus, updateDeliveryRecordTruckWorkers, updateDeliveryRecordProposedDateTime, updateDeliveryRecordDeliveryAddressID } from "functions/Delivery";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import DeliveryTeamChooseModal from "../deliveryTeam/DeliveryTeamChooseModal";
import { updateDeliveryRemark } from "functions/Delivery";
import DeliveryAddressBadge from "../components/DeliveryAddressBadge";
import AddressRelatedDeliveryRecord from "../address/AddressRelatedDeliveryRecord";
import { DeliveryBasketListGlobal } from "context/DeliveryBasketContext";


Dropzone.autoDiscover = false;

function DeliveryRecordEditModal(props) {
    const { t } = useTranslation();

    // Traffic Control
    const [trafficControlInvoiceIDToProhibit, setTrafficControlInvoiceIDToProhibit] = React.useState("");

    React.useEffect(() => {
        setTrafficControlInvoiceIDToProhibit(props.trafficControlInvoiceIDToProhibit);
    }, [props.trafficControlInvoiceIDToProhibit]);


    const [deliveryPhoto1, setDeliveryPhoto1] = React.useState({
        photo1ID: "",
        photo1Base64Content: "",
        photo1Remark: "",
        photo1Order: "",
        photo1ImageUploadTime: Date(),
        photo1UploadUserID: "",
        photo1UploadUserNickName: ""
    });
    const [deliveryPhoto2, setDeliveryPhoto2] = React.useState({
        photo2ID: "",
        photo2Base64Content: "",
        photo2Remark: "",
        photo2Order: "",
        photo2ImageUploadTime: Date(),
        photo2UploadUserID: "",
        photo2UploadUserNickName: ""
    });
    const [deliveryPhoto3, setDeliveryPhoto3] = React.useState({
        photo3ID: "",
        photo3Base64Content: "",
        photo3Remark: "",
        photo3Order: "",
        photo3ImageUploadTime: Date(),
        photo3UploadUserID: "",
        photo3UploadUserNickName: ""
    });
    const [deliveryPhoto4, setDeliveryPhoto4] = React.useState({
        photo4ID: "",
        photo4Base64Content: "",
        photo4Remark: "",
        photo4Order: "",
        photo4ImageUploadTime: Date(),
        photo4UploadUserID: "",
        photo4UploadUserNickName: ""
    });
    const { photo1ID, photo1Base64Content, photo1Remark, photo1Order, photo1ImageUploadTime, photo1UploadUserID, photo1UploadUserNickName } = deliveryPhoto1;
    const { photo2ID, photo2Base64Content, photo2Remark, photo2Order, photo2ImageUploadTime, photo2UploadUserID, photo2UploadUserNickName } = deliveryPhoto2;
    const { photo3ID, photo3Base64Content, photo3Remark, photo3Order, photo3ImageUploadTime, photo3UploadUserID, photo3UploadUserNickName } = deliveryPhoto3;
    const { photo4ID, photo4Base64Content, photo4Remark, photo4Order, photo4ImageUploadTime, photo4UploadUserID, photo4UploadUserNickName } = deliveryPhoto4;


    const onChangeDeliveryPhoto1 = (photoIDToUpdate, photoBase64ContentToUpdate, photoRemark, photoOrder, photoImageUploadTime, photoImageUploadUserID, photoImageUploadUserNickName) => {
        setDeliveryPhoto1({
            photo1ID: photoIDToUpdate,
            photo1Base64Content: photoBase64ContentToUpdate,
            photo1Remark: photoRemark,
            photo1Order: photoOrder,
            photo1ImageUploadTime: photoImageUploadTime,
            photo1UploadUserID: photoImageUploadUserID,
            photo1UploadUserNickName: photoImageUploadUserNickName
        });
    };
    const onChangeDeliveryPhoto2 = (photoIDToUpdate, photoBase64ContentToUpdate, photoRemark, photoOrder, photoImageUploadTime, photoImageUploadUserID, photoImageUploadUserNickName) => {
        setDeliveryPhoto2({
            photo2ID: photoIDToUpdate,
            photo2Base64Content: photoBase64ContentToUpdate,
            photo2Remark: photoRemark,
            photo2Order: photoOrder,
            photo2ImageUploadTime: photoImageUploadTime,
            photo2UploadUserID: photoImageUploadUserID,
            photo2UploadUserNickName: photoImageUploadUserNickName
        });
    };
    const onChangeDeliveryPhoto3 = (photoIDToUpdate, photoBase64ContentToUpdate, photoRemark, photoOrder, photoImageUploadTime, photoImageUploadUserID, photoImageUploadUserNickName) => {
        setDeliveryPhoto3({
            photo3ID: photoIDToUpdate,
            photo3Base64Content: photoBase64ContentToUpdate,
            photo3Remark: photoRemark,
            photo3Order: photoOrder,
            photo3ImageUploadTime: photoImageUploadTime,
            photo3UploadUserID: photoImageUploadUserID,
            photo3UploadUserNickName: photoImageUploadUserNickName
        });
    };
    const onChangeDeliveryPhoto4 = (photoIDToUpdate, photoBase64ContentToUpdate, photoRemark, photoOrder, photoImageUploadTime, photoImageUploadUserID, photoImageUploadUserNickName) => {
        setDeliveryPhoto4({
            photo4ID: photoIDToUpdate,
            photo4Base64Content: photoBase64ContentToUpdate,
            photo4Remark: photoRemark,
            photo4Order: photoOrder,
            photo4ImageUploadTime: photoImageUploadTime,
            photo4UploadUserID: photoImageUploadUserID,
            photo4UploadUserNickName: photoImageUploadUserNickName
        });
    };

    const [signatureData, setSignatureData] = React.useState({
        signature: "",
        signatureRecordDateTime: new Date(),
        signatureUploadUser: "",
        signatureUploadUserNickName: ""
    });
    const { signature, signatureRecordDateTime, signatureUploadUser, signatureUploadUserNickName } = signatureData;

    const onChangeSignatureData = (imageBase64Content, imageUploadTime, imageUploadUserID, imageUploadUserNickName) => {
        setSignatureData({ signature: imageBase64Content, signatureRecordDateTime: imageUploadTime, signatureUploadUser: imageUploadUserID, signatureUploadUserNickName: imageUploadUserNickName });
    };


    // Basic control
    const [deliveryStatusArray, setDeliveryStatusArray] = React.useState([]);
    const [deliveryRecordData, setDeliveryRecordData] = React.useState(null);
    const [selectedDeliverySubitem, setSelectedDeliverySubitem] = React.useState([]);
    const [customerSelectedData, setCustomerSelectedData] = React.useState([]);
    const [driverData, setDriverData] = React.useState([]);
    const [carData, setCarData] = React.useState([]);
    const [deliveryRecordTruckWorkerMatches, setDeliveryRecordTruckWorkerMatches] = React.useState(deliveryRecordData?.DeliveryRecordTruckWorkerMatches);

    // For form control

    // For fast mode 
    const [showQuickActionSection, setShowQuickActionSection] = React.useState(true);
    const [proposedDateTimeFastModeChanged, setProposedDateTimeFastModeChanged] = React.useState(false);
    const [addressFastModeChanged, setAddressFastModeChanged] = React.useState(false);
    const [deliveryRemarkFastModeChanged, setDeliveryRemarkFastModeChanged] = React.useState(false);

    // For detail mode 
    const [showDeliverySection, setShowDeliverySection] = React.useState(true);
    const [showProofSection, setShowProofSection] = React.useState(false);
    const [showCustomerSection, setShowCustomerSection] = React.useState(false);
    const [showInvoiceSection, setShowInvoiceSection] = React.useState(false);
    const [showAllSection, setShowAllSection] = React.useState(false);

    const [deliveryDetailEdited, setDeliveryDetailEdited] = React.useState(false);
    const [signatureIsOpen, setSignatureIsOpen] = React.useState(false);

    // general control
    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);


    // for modal 
    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [customerSelectedDataForCustomerAction, setCustomerSelectedDataForCustomerAction] = React.useState([]);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceMessage, setInvoiceSelectedDataForInvoiceMessage] = React.useState([]);
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceEdit, setInvoiceSelectedDataForInvoiceEdit] = React.useState([]);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
    const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);
    const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
    const [modalAddProduct, setModalAddProduct] = React.useState(false);
    const [modalEditProduct, setModalEditProduct] = React.useState(false);
    const [modalDeliveryAddressEditIsOpen, setModalDeliveryAddressEditIsOpen] = React.useState(false);
    const [modalCreateDeliveryAddressIsOpen, setModalCreateDeliveryAddressIsOpen] = React.useState(false);
    const [modalDeliveryTeamChooseModalIsOpen, setModalDeliveryTeamChooseModalIsOpen] = React.useState(false);

    const [modalAddressRelatedDeliveryRecordIsOpen, setModalAddressRelatedDeliveryRecordIsOpen] = React.useState(false);

    // Address management
    const [additionalAddressDataArray, setAdditionalAddressDataArray] = React.useState([]);
    const [reloadDeliveryAddressSelect, setReloadDeliveryAddressSelect] = React.useState(false);
    const [specialMessageForAddressEditProcess, setSpecialMessageForAddressEditProcess] = React.useState("");

    // [{localDeliverySubitemID: uuid(), good, ...}]
    const [deliverySubitemList, setDeliverySubitemsTable] = React.useState([]);
    const [proposedDateTime, setproposedDateTime] = React.useState(new Date(deliveryRecordData?.proposedDateTime));
    const [deliveryRecordDateTime, setDeliveryRecordDateTime] = React.useState(new Date(deliveryRecordData?.deliveryRecordDateTime));
    const [startDeliveryDateTime, setStartDeliveryDateTime] = React.useState(new Date(deliveryRecordData?.startDeliveryDateTime));
    const [finishDateTime, setFinishDateTime] = React.useState(new Date(deliveryRecordData?.finishDateTime));

    const [displayInvoiceData, setDisplayInvoiceData] = React.useState([]);
    const [deleteSelectedLocalDeliverySubitemID, setDeleteSelectedLocalDeliverySubitemID] = React.useState("");
    //const [signature, setSignature] = React.useState(""); // "" means no signature


    const [gridPhoto, setGridPhoto] = React.useState(1);


    //Post Data
    const [deliveryStatus, setDeliveryStatus] = React.useState(deliveryRecordData?.deliveryStatus);
    const [deliveryID, setDeliveryID] = React.useState(deliveryRecordData?.deliveryID);
    const [driverID, setDriverID] = React.useState(deliveryRecordData?.driverID);
    const [carID, setCarID] = React.useState(deliveryRecordData?.carID);
    const [deliveryRemark, setDeliveryRemark] = React.useState(deliveryRecordData?.deliveryRemark);


    // deliveryRecordTruckWorkerIDsToSubmit = [{truckWorkerID: 'ADF'}, {truckWorkerID: 'ADF'}, ....]
    const [deliveryRecordTruckWorkerIDsToSubmit, setDeliveryRecordTruckWorkerIDsToSubmit] = React.useState([]);


    const [selectedAddressID, setSelectedAddressID] = React.useState("");

    const [selectedAddressData, setSelectedAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    // Context
    const { isThisDeliveryRecordExist, addSingleDeliveryRecordToGlobalList, deleteSingleDeliveryRecordToGlobalList } = DeliveryBasketListGlobal();

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = selectedAddressData;

    // const editable = doDetail?.deliveryStatus === "排程中";
    const isDriver = localStorage.getItem("rights") === "driver";

    React.useEffect(() => {
        setShowCustomerSection(showAllSection);
        setShowDeliverySection(showAllSection);
        setShowInvoiceSection(showAllSection);
        setShowProofSection(showAllSection);
    }, [showAllSection]);

    React.useEffect(() => {
        const deleteDeliveryRecordSubitems = () => {
            setDeliverySubitemsTable(
                deliverySubitemList.filter(
                    (data) => data.localDeliverySubitemID !== deleteSelectedLocalDeliverySubitemID
                )
            );
            setDeleteSelectedLocalDeliverySubitemID("");

        };

        if (deleteSelectedLocalDeliverySubitemID !== "") {
            deleteDeliveryRecordSubitems();
        }
    }, [deleteSelectedLocalDeliverySubitemID]);

    React.useEffect(() => {
        if (modalDeliveryAddressEditIsOpen) {
            setSpecialMessageForAddressEditProcess(t("This address can be edited"))
        } else if (modalCreateDeliveryAddressIsOpen) {
            setSpecialMessageForAddressEditProcess(t("This address is related to other delivery record. You can only add a new one"))
        } else {
            setSpecialMessageForAddressEditProcess("");
        }
    }, [modalDeliveryAddressEditIsOpen, modalCreateDeliveryAddressIsOpen]);

    // get the data for the choice
    React.useEffect(() => {
        const getDriver = async () => {
            let driverData = await getDriverSelect2();
            setDriverData(driverData);
        };
        const getCar = async () => {
            let car = await getCarSelect2();
            setCarData(car);
        };
        if (props.show) {
            getDriver();
            getCar();
            setDeliveryStatusArray(getDeliveryStatusArray());
        }
    }, [props.show]);

    //Initialize Effect
    React.useEffect(() => {
        if (props.show) {
            getDeliveryRecordFromDB();
            getSignatureFromServer();
            getPhotoFromServer();
        }
    }, [props.show, refreshTable]);


    // 由於呢個 edit form
    // 所以唔會有 reset form 
    // 由 DB download data 就係 reset
    const getDeliveryRecordFromDB = async () => {
        setIsLoading(true);
        let data = await getDeliveryRecordsById(props.deliveryData?.deliveryID);
        setDeliveryRecordData(data);
        replaceItemArrayToTable(data?.DeliveryRecordSubitems);
        setproposedDateTime(new Date(data?.proposedDateTime));
        setDeliveryRecordDateTime(new Date(data?.deliveryRecordDateTime));
        setStartDeliveryDateTime(new Date(data?.startDeliveryDateTime));
        setFinishDateTime(new Date(data?.finishDateTime));
        setDeliveryID(data?.deliveryID);
        setDeliveryStatus(data?.deliveryStatus);
        setDriverID(data?.driverID);
        setCarID(data?.carID);
        setDeliveryRemark(data?.deliveryRemark);

        setDeliveryRecordTruckWorkerMatches(data?.DeliveryRecordTruckWorkerMatches);

        setSelectedAddressID(data?.DeliveryAddress?.deliveryAddressID);

        setSelectedAddressData({
            addressDetailUnitFloor: data?.DeliveryAddress.addressDetailUnitFloor,
            addressDetailBuilding: data?.DeliveryAddress.addressDetailBuilding,
            addressDetailStreet: data?.DeliveryAddress.addressDetailStreet,
            addressDetailDistrict: data?.DeliveryAddress.addressDetailDistrict,
            addressDetailMainDistrict: data?.DeliveryAddress.addressDetailMainDistrict,
            addressRemark: data?.DeliveryAddress.addressRemark,
            addressGPSLatitude: data?.DeliveryAddress.addressGPSLatitude,
            addressGPSLongtitude: data?.DeliveryAddress.addressGPSLongtitude
        });

        setCustomerSelectedData(data?.Invoice?.Customer);
        setDisplayInvoiceData(await getSingleInvoice(data?.Invoice?.invoiceID));

        // Reset the submit data
        setDeliveryRecordTruckWorkerIDsToSubmit([]);

        // reset the operation data
        setAdditionalAddressDataArray([]);
        setReloadDeliveryAddressSelect(false);
        setSpecialMessageForAddressEditProcess("");

        setIsLoading(false);

        // If the form is reset
        // turn off the edited state
        setDeliveryDetailEdited(false);
    };


    async function handleOnChangeDeliveryRemark(delivetRemarkToSet) {
        setDeliveryRemark(delivetRemarkToSet);
    }


    const getDriverContact = () => {
        try {
            const res = driverData.filter((val) => val.id === driverID);
            return res[0]?.SystemUser?.phoneNumber;
        } catch {
            return driverData[0]?.SystemUser?.phoneNumber;
        }
    };


    // Address management
    function handleOnChangeDeliveryAddressID(deliveryAddressID) {
        setSelectedAddressID(deliveryAddressID);
    }
    async function handleOnChangeDeliveryAddressData(deliveryAddressData) {

        let relatedDeliveryRecordList = [];
        if (deliveryAddressData && deliveryAddressData.deliveryAddressID) {
            relatedDeliveryRecordList = await getDeliveryRecordsByAddressID(deliveryAddressData.deliveryAddressID);
        }
        deliveryAddressData.relatedDeliveryRecordList = relatedDeliveryRecordList;
        setSelectedAddressData({
            deliveryAddressID: deliveryAddressData?.deliveryAddressID,
            addressDetailUnitFloor: deliveryAddressData?.addressDetailUnitFloor,
            addressDetailBuilding: deliveryAddressData?.addressDetailBuilding,
            addressDetailStreet: deliveryAddressData?.addressDetailStreet,
            addressDetailDistrict: deliveryAddressData?.addressDetailDistrict,
            addressDetailMainDistrict: deliveryAddressData?.addressDetailMainDistrict,
            addressRemark: deliveryAddressData?.addressRemark,
            addressGPSLatitude: deliveryAddressData?.addressGPSLatitude,
            addressGPSLongtitude: deliveryAddressData?.addressGPSLongtitude,
            relatedDeliveryRecordList: deliveryAddressData?.relatedDeliveryRecordList
        });
    }
    // Update delivery address
    const editDeliveryAddressProcess = async (targetDeliveryAddressID) => {
        if (!targetDeliveryAddressID || targetDeliveryAddressID === '') {
            return; // do nothing
        }

        // Step 1 
        // Check how many delivery record is related to this address
        setIsLoading(true);
        let deliveryRecordArrayByAddress = await getDeliveryRecordsByAddressID(targetDeliveryAddressID); // must be an array
        setIsLoading(false);

        // Array check
        if (!deliveryRecordArrayByAddress || !Array.isArray(deliveryRecordArrayByAddress)) {
            // Error 
            return;
        }

        // Case 1
        if (!deliveryRecordArrayByAddress || deliveryRecordArrayByAddress.length <= 0) {
            // This is an address without any delivery record relating
            // Allow edit this address
            setModalDeliveryAddressEditIsOpen(true);
            return;
        }

        // Case 2
        if (deliveryRecordArrayByAddress && deliveryRecordArrayByAddress.length === 1) {
            // There is a delivery record relating
            // Check if it is the same as current delivery record
            if (deliveryRecordData.deliveryID === deliveryRecordArrayByAddress[0].deliveryID) {
                // means this is the current delivery record
                // Allow edit this address
                setModalDeliveryAddressEditIsOpen(true);
                return;
            } else {
                // means relating to other delivery order
                // dont allow edit 
                // go to add new
                setModalCreateDeliveryAddressIsOpen(true);
                return;
            }
        }

        // Case 3
        if (deliveryRecordArrayByAddress && deliveryRecordArrayByAddress.length >= 2) {
            // There is many delivery records relating
            // Dont allow edit
            // Allow add
            setModalCreateDeliveryAddressIsOpen(true);
            return;
        }

    }
    // End of Address management



    // Product management
    const onAddProduct = () => {
        if (isAccessible('deliveryEditAddProduct')) {
            setModalAddProduct(true);
            setDeliveryDetailEdited(true);
        }
    }

    const onEditProduct = (deliverySubitemItem) => {
        if (isAccessible('deliveryEditEditProduct')) {
            setSelectedDeliverySubitem(deliverySubitemItem);
            setModalEditProduct(true);
            setDeliveryDetailEdited(true);
        }
    }

    const onDeleteProduct = (localDeliverySubitemID) => {
        if (isAccessible('deliveryEditDeleteProduct')) {
            setDeleteSelectedLocalDeliverySubitemID(localDeliverySubitemID);
            setDeliveryDetailEdited(true);
        }
    }

    function replaceItemArrayToTable(dataArray) {
        if (dataArray && Array.isArray(dataArray)) {
            if (dataArray.length >= 1) {
                let arrayToTable = dataArray.map((element) => {
                    return Object.assign({}, {
                        localDeliverySubitemID: uuid4(),
                        good: element.good ? element.good : element.Good, // if the data come from dB, the good is "Good"
                        // goodID: element.goodID,
                        deliverySubitemRemark: element.deliverySubitemRemark,
                        deliverySubitemQuantity: element.quantity ? element.quantity : element.deliverySubitemQuantity
                    });
                });
                setDeliverySubitemsTable([...arrayToTable]);

            } else {
                setDeliverySubitemsTable([]);
            }
        }
    }

    function addItemArrayToTable(dataArray) {
        if (dataArray && Array.isArray(dataArray) && dataArray.length >= 1) {
            let arrayToTable = dataArray.map((element) => {
                return Object.assign({}, {
                    localDeliverySubitemID: uuid4(),
                    good: element.good ? element.good : element.Good, // if the data come from dB, the good is "Good"
                    // goodID: element.goodID,
                    deliverySubitemRemark: element.deliverySubitemRemark,
                    deliverySubitemQuantity: element.quantity ? element.quantity : element.deliverySubitemQuantity
                });
            });
            setDeliverySubitemsTable([...deliverySubitemList, ...arrayToTable]);
        }
    }

    function editItemInTable(itemToEdit) {

        let arrData = deliverySubitemList.filter(
            (val) => val.localDeliverySubitemID !== itemToEdit.localDeliverySubitemID
        );

        let modObj = Object.assign({}, {
            localDeliverySubitemID: itemToEdit.localDeliverySubitemID,
            good: itemToEdit.good ? itemToEdit.good : itemToEdit.Good, // if the data come from dB, the good is "Good"
            // goodID: itemToEdit.goodID,
            deliverySubitemRemark: itemToEdit.deliverySubitemRemark,
            deliverySubitemQuantity: itemToEdit.quantity ? itemToEdit.quantity : itemToEdit.deliverySubitemQuantity
        });

        setDeliverySubitemsTable([...arrData, modObj]);
    }
    // End of Product management


    // Update this delivery record
    const updateDo = async (e) => {
        e.preventDefault();
        let deliveryAddressID = selectedAddressID;


        try {
            // check the data before upload

            // check addressID
            if (deliveryAddressID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t("Please choose an address")
                });
                return;
            }
            // check driver
            if (driverID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a driver')
                });
                return;
            }
            // check car
            if (carID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a car')
                });
                return;
            }

            let truckWorkerIDs = [];
            if (deliveryRecordTruckWorkerIDsToSubmit && Array.isArray(deliveryRecordTruckWorkerIDsToSubmit) && deliveryRecordTruckWorkerIDsToSubmit.length >= 0) {
                // deliveryRecordTruckWorkerToSubmit = [{truckWorkerID: 'aSDF'}, {truckWorkerID: 'ffddf'}, ...]


                // truckWorkerIDs = [{truckWorkerID: 'aSDF'}, {truckWorkerID: 'ffddf'}, ...]
                truckWorkerIDs = deliveryRecordTruckWorkerIDsToSubmit.map((item) => {
                    return { truckWorkerID: item.id };
                });
            }

            //Fix the deliverySubitemList before submit
            let deliverySubitemListToSubmit = [];
            if (deliverySubitemList && deliverySubitemList.length >= 1) {
                deliverySubitemList.forEach(element => {
                    deliverySubitemListToSubmit.push({
                        deliverySubitemQuantity: element.deliverySubitemQuantity,
                        deliverySubitemRemark: element.deliverySubitemRemark,
                        goodID: element.good.goodID
                    })
                });
            }

            const dataSubmit = {
                deliveryID: deliveryRecordData.deliveryID,
                deliveryAddressID: deliveryAddressID,
                deliveryStatus: deliveryStatus,
                proposedDateTime: proposedDateTime, // update DO doesnt require deliveryRecordDateTime
                startDeliveryDateTime: startDeliveryDateTime,
                finishDateTime: finishDateTime,
                driverID: driverID,
                carID: carID,
                deliveryRemark: deliveryRemark,
                deliveryRecordSubitems: deliverySubitemListToSubmit,
                truckWorkerIDs: truckWorkerIDs,
            };

            setIsLoading(true);

            await updateDeliveryFull(dataSubmit);

            setIsLoading(false);
            Notifications({ type: "update", message: t("Data Updated Successfuly!") });
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update carID to server directly
    const updateCarIDToServer = async (carIDToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }
            // check car
            if (carIDToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a car')
                });
                return;
            }
            setIsLoading(true);
            await updateDeliveryRecordCarID(deliveryRecordData.deliveryID, carIDToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update driverID to server directly
    const updateDriverIDToServer = async (driverIDToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }
            // check car
            if (driverIDToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a driver')
                });
                return;
            }
            setIsLoading(true);
            await updateDeliveryRecordDriverID(deliveryRecordData.deliveryID, driverIDToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update delivery status to server directly
    const updateDeliveryStatusToServer = async (deliveryStatusToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }
            // check car
            if (deliveryStatusToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a delivery status')
                });
                return;
            }
            setIsLoading(true);
            await updateDeliveryRecordDeliveryStatus(deliveryRecordData.deliveryID, deliveryStatusToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update delivery address ID to server directly
    const updateDeliveryAddressIDToServer = async (deliveryAddressIDToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }
            // check car
            if (deliveryAddressIDToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a delivvery address')
                });
                return;
            }
            setIsLoading(true);
            await updateDeliveryRecordDeliveryAddressID(deliveryRecordData.deliveryID, deliveryAddressIDToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };


    // Update deliveryRemark to server directly
    const updateDeliveryRemarkToServer = async (deliveryRemarkToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }

            // Allow empty remark
            // ""

            // check remark
            // if (deliveryRemarkToUpdate === "") {
            //     Notifications({
            //         type: "dataCheck",
            //         message: t('Please write some remark')
            //     });
            //     return;
            // }

            setIsLoading(true);
            await updateDeliveryRemark(deliveryRecordData.deliveryID, deliveryRemarkToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };




    // Update delivery status to server directly
    const updateProposedDatetimeToServer = async (proposedDateTimeToUpdate) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }

            // // check car
            // if (proposedDateTimeToUpdate === "") {
            //     Notifications({
            //         type: "dataCheck",
            //         message: t('Please choose a delivery status')
            //     });
            //     return;
            // }

            setIsLoading(true);
            await updateDeliveryRecordProposedDateTime(deliveryRecordData.deliveryID, proposedDateTimeToUpdate);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update truckworker to server directly
    const updateTruckWorkerListToServer = async (truckWorkerIDs) => {
        try {
            // check the data before upload
            // check deliveryID
            if (deliveryRecordData.deliveryID === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please check deliveryID')
                });
                return;
            }
            // check car

            if (!truckWorkerIDs || !Array.isArray(truckWorkerIDs) || truckWorkerIDs.length < 0) {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose truck worker')
                });
                return;
            }

            setIsLoading(true);
            await updateDeliveryRecordTruckWorkers(deliveryRecordData.deliveryID, truckWorkerIDs);
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })
        }
    };

    // Update this invoice
    const editInvoice = (invoice) => {
        if (!invoice) return;

        if (trafficControlInvoiceIDToProhibit && trafficControlInvoiceIDToProhibit === invoice?.invoiceID) {
            Notifications({
                type: "trafficControl",
                message: t("Cannot edit this invoice"),
            });
            return;
        }
        setInvoiceSelectedDataForInvoiceEdit(invoice);
        setModalInvoiceEditIsOpen(true);
    }

    // Get signature from a server
    const getSignatureFromServer = async () => {
        setIsLoading(true);
        let res = await getDeliverySignature(props.deliveryData.deliveryID);
        onChangeSignatureData(res.data.imageBase64Content, res.data.imageUploadTime, res.data.imageUploadUserID, res.data.imageUploadUserData?.nickName)
        setIsLoading(false);
    };

    // Upload a signature
    const uploadSignatureNow = async (signatureImgToUpload) => {
        setIsLoading(true);
        await addDeliverySignature(deliveryRecordData.deliveryID, signatureImgToUpload);
        setIsLoading(false);
    }

    // Delete a signature
    const deleteSignatureNow = async () => {
        setIsLoading(true);
        await deleteDeliverySignature(deliveryRecordData.deliveryID);
        setIsLoading(false);
    }

    // Get photo from server
    const getPhotoFromServer = async () => {
        setIsLoading(true);
        let maxGridPhotoCount = 1; // max = 4
        let res = await getDeliveryPhoto(props.deliveryData.deliveryID);

        if (res?.data?.rows.length <= 0) {
            // Clear all photo
            setDeliveryPhoto1({
                photo1ID: "",
                photo1Base64Content: "",
                photo1Remark: "",
                photo1Order: "",
                photo1ImageUploadTime: Date(),
                photo1UploadUserID: "",
                photo1UploadUserNickName: ""
            });
            setDeliveryPhoto2({
                photo2ID: "",
                photo2Base64Content: "",
                photo2Remark: "",
                photo2Order: "",
                photo2ImageUploadTime: Date(),
                photo2UploadUserID: "",
                photo2UploadUserNickName: ""
            });
            setDeliveryPhoto3({
                photo3ID: "",
                photo3Base64Content: "",
                photo3Remark: "",
                photo3Order: "",
                photo3ImageUploadTime: Date(),
                photo3UploadUserID: "",
                photo3UploadUserNickName: ""
            });
            setDeliveryPhoto4({
                photo4ID: "",
                photo4Base64Content: "",
                photo4Remark: "",
                photo4Order: "",
                photo4ImageUploadTime: Date(),
                photo4UploadUserID: "",
                photo4UploadUserNickName: ""
            });
        } else {
            for (let i = 0; i < res?.data?.rows.length; i += 1) {
                const obj = {
                    id: res?.data?.rows[i]?.imageID,
                    val: res?.data?.rows[i]?.imageBase64Content,
                    remark: res?.data?.rows[i]?.imageRemark,
                    order: res?.data?.rows[i]?.imageOrder,
                    imageUploadTime: res?.data?.rows[i]?.imageUploadTime,
                    imageUploadUserID: res?.data?.rows[i]?.imageUploadUserID,
                    imageUploadUserNickName: res?.data?.rows[i]?.imageUploadUserData?.nickName
                };
                if (obj.id !== "") {
                    if (maxGridPhotoCount <= Number(obj.order)) {
                        maxGridPhotoCount = Number(obj.order);
                        setGridPhoto(maxGridPhotoCount);
                    }
                    switch (Number(obj.order)) {
                        case 1:
                            setDeliveryPhoto1({
                                photo1ID: obj.id,
                                photo1Base64Content: obj.val,
                                photo1Remark: obj.remark,
                                photo1Order: obj.order,
                                photo1ImageUploadTime: obj.imageUploadTime,
                                photo1UploadUserID: obj.imageUploadUserID,
                                photo1UploadUserNickName: obj.imageUploadUserNickName
                            });
                            break;
                        case 2:
                            setDeliveryPhoto2({
                                photo2ID: obj.id,
                                photo2Base64Content: obj.val,
                                photo2Remark: obj.remark,
                                photo2Order: obj.order,
                                photo2ImageUploadTime: obj.imageUploadTime,
                                photo2UploadUserID: obj.imageUploadUserID,
                                photo2UploadUserNickName: obj.imageUploadUserNickName
                            });
                            break;
                        case 3:
                            setDeliveryPhoto3({
                                photo3ID: obj.id,
                                photo3Base64Content: obj.val,
                                photo3Remark: obj.remark,
                                photo3Order: obj.order,
                                photo3ImageUploadTime: obj.imageUploadTime,
                                photo3UploadUserID: obj.imageUploadUserID,
                                photo3UploadUserNickName: obj.imageUploadUserNickName
                            });
                            break;
                        case 4:
                            setDeliveryPhoto4({
                                photo4ID: obj.id,
                                photo4Base64Content: obj.val,
                                photo4Remark: obj.remark,
                                photo4Order: obj.order,
                                photo4ImageUploadTime: obj.imageUploadTime,
                                photo4UploadUserID: obj.imageUploadUserID,
                                photo4UploadUserNickName: obj.imageUploadUserNickName
                            });
                            break;
                    }

                }
            }
        }
        setIsLoading(false);
    };

    // Count photo
    const countGridPhoto = () => {
        let gridPhotoCount = 0;
        if (photo1ID !== "") gridPhotoCount++;
        if (photo2ID !== "") gridPhotoCount++;
        if (photo3ID !== "") gridPhotoCount++;
        if (photo4ID !== "") gridPhotoCount++;
        return gridPhotoCount;
    }

    // Upload a image
    const uploadImgNowReturnPhotoID = async (imageToUpload, imageRemark, imageOrder) => {
        if (!isFileImage(imageToUpload)) {
            return "";
        } else {
            setIsLoading(true);
            let photoIDFromAPI = await addDeliveryPhoto(deliveryRecordData.deliveryID, await getBase64PhotoHeadRemoved(imageToUpload), imageRemark, imageOrder);
            setIsLoading(false);
            return photoIDFromAPI;
        }
    };

    // Update a image
    const updateImgDataNowReturnPhotoID = async (imageID, imageRemark, imageOrder) => {
        setIsLoading(true);
        let photoIDFromAPI = await updateDeliveryPhotoMetaData(deliveryRecordData.deliveryID, imageID, imageRemark, imageOrder);
        setIsLoading(false);
        return photoIDFromAPI;

    };

    // Delete a image
    const deleteImgNow = async (photoID) => {
        if (photoID === "") return;
        setIsLoading(true);
        await deleteDeliveryPhoto(photoID, deliveryRecordData.deliveryID);
        setIsLoading(false);
    };

    // Download a image
    const downloadImg = async (photoID, photoBase64Data) => {
        var a = document.createElement("a");
        a.href = "data:image/png;base64," + photoBase64Data;
        a.download = photoID + ".png";
        a.click();
    };


    // Delete this delivery record
    async function deleteConfirmationDO(deliveryID) {
        // if (trafficControlDeliveryIDToProhibit && trafficControlDeliveryIDToProhibit === deliveryID) {
        //     Notifications({
        //         type: "trafficControl",
        //         message: t("Cannot delete this delivery record"),
        //     });
        //     return;
        // }

        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: deliveryID,
            callback: deleteDOFunc,
        };
        Notifications(alert);
    }

    // Delete this delivery record API Call
    async function deleteDOFunc(id) {
        try {
            let returnData = await deleteDeliveryRecord(id);
            Notifications({ type: "delete", status: returnData.status, message: returnData.message });
            if (returnData.code === 200) {
                // close this form
                props.onHide();
            }
        } catch (err) {
            Notifications({ type: "error", message: err });
        }
    }

    // Clone this delivery record
    function cloneDeliveryRecordConfirmation(deliveryRecordToClone) {
        let alert = {
            type: "cloneDeliveryRecordConfirmation",
            title: "Are you Sure?",
            text: t("Ready to clone a delivery record"),
            dataToPass: deliveryRecordToClone,
            callback: cloneDeliveryRecord,
        };
        Notifications(alert);
    }

    // Clone this delivery record
    function cloneDeliveryRecord(deliveryRecordToClone) {
        setModalDeliveryRecordAddIsOpen(true);
    }


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >

                {isLoading ? <SpinnerOverlay /> : ""}
                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >

                    <div>
                        <div>
                            {t("Edit Delivery Order")}
                        </div>
                        <div className="mt-2">
                            <Badge color="primary" className="statusBadge">{combineAddress(selectedAddressData)}</Badge>
                        </div>
                        {showAllSection &&
                            <div className="mt-2">
                                <Badge color="primary" className="statusBadge">{deliveryID}</Badge>
                            </div>
                        }
                    </div>
                    <div className="mt-1">
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => { setShowAllSection(!showAllSection) }}>
                            <i className={`fa fa-angle-${showAllSection ? "up" : "down"}`} />
                        </Button>
                        <Button
                            className="ml--1"
                            color="primary"
                            size="sm"
                            onClick={() => {
                                setModalDeliveryRecordMessageIsOpen(true);
                            }}>
                            <Trans>Delivery PDF</Trans>
                        </Button>
                        {isAccessible('deliveryEditShowCloneDeliveryButton') &&
                            <Button
                                className="ml--1"
                                color="primary"
                                size="sm"
                                onClick={() => { cloneDeliveryRecordConfirmation(deliveryRecordData) }}>
                                <Trans>Copy delivery</Trans>
                                <span>{getAccessibleTagForSafeMode('deliveryEditShowCloneDeliveryButton')}</span>
                            </Button>
                        }
                        <Button
                            className="ml--1"
                            color={isThisDeliveryRecordExist(deliveryRecordData) ? "success" : "danger"}
                            size="sm"
                            onClick={() => {
                                if (isThisDeliveryRecordExist(deliveryRecordData)) {
                                    deleteSingleDeliveryRecordToGlobalList(deliveryRecordData);
                                } else {
                                    addSingleDeliveryRecordToGlobalList(deliveryRecordData);
                                }
                            }}>
                            {isThisDeliveryRecordExist(deliveryRecordData) ? 
                            <Trans>In delivery basket</Trans> :
                            <Trans>Add to delivery basket</Trans>
                        }
                        </Button>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12" lg="12">


                            {/* ButtonGroup to choose mode */}
                            <Row className="mb-4">
                                <Col >
                                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                                        <Button className={classnames({ active: showQuickActionSection })} color="secondary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (deliveryDetailEdited) {
                                                    Notifications({
                                                        type: "advice",
                                                        message: t("Changes not saved")
                                                    });
                                                } else {
                                                    setShowQuickActionSection(!showQuickActionSection);
                                                }
                                            }}>
                                            <input
                                                autoComplete="off"
                                                name="options"
                                                type="radio"
                                                value={showQuickActionSection}
                                            />
                                            <Trans>Fast mode</Trans>
                                        </Button>
                                        <Button className={classnames({ active: !showQuickActionSection })} color="secondary"
                                            onClick={() => {
                                                setShowQuickActionSection(!showQuickActionSection);
                                            }}>
                                            <input
                                                autoComplete="off"
                                                name="options"
                                                type="radio"
                                                value={!showQuickActionSection}
                                            />
                                            <Trans>Detail mode</Trans>
                                        </Button>

                                    </ButtonGroup>
                                </Col>
                            </Row>
                            {/* End of ButtonGroup to choose mode */}


                            {/* Quick action */}
                            {showQuickActionSection && (
                                <Row>
                                    <Col sm="12" lg="12">
                                        <div className="card-wrapper">
                                            <Card className="mb-4">
                                                <CardHeader>
                                                    <Row className="">
                                                        <Col>
                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryStatus')}</span>
                                                            <span className="mt-1">
                                                                <DropdownControl
                                                                    disabled={!isAccessible('deliveryEditChooseDeliveryStatus')}
                                                                    defaultColor="primary"
                                                                    dropdownName={t("Delivery Status")}
                                                                    useTranslate={true}
                                                                    arrayIDTextFormat={deliveryStatusArray}
                                                                    valueID={deliveryStatus}
                                                                    onSelectValueID={async (valueID) => {
                                                                        // For this button to update the text (value)
                                                                        setDeliveryStatus(valueID);

                                                                        // Approach changed
                                                                        // Fast mode 
                                                                        // Just directly update the DB
                                                                        await updateDeliveryStatusToServer(valueID);

                                                                        // Get the data from DB
                                                                        // Refresh the whole form
                                                                        getDeliveryRecordFromDB();
                                                                    }}
                                                                />
                                                            </span>
                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryTeam')}</span>
                                                            <span className="mt-1 ml-1">
                                                                <Button
                                                                    disabled={!isAccessible('deliveryEditChooseDeliveryTeam')}
                                                                    color="warning"
                                                                    onClick={() => {
                                                                        setModalDeliveryTeamChooseModalIsOpen(true);
                                                                    }} >
                                                                    <span className="btn-inner--icon mr-1">
                                                                        <i className="fas fa-plus" />
                                                                    </span>
                                                                    <span className="btn-inner--text">
                                                                        {t("Team")}
                                                                    </span>
                                                                </Button>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseCar')}</span>
                                                            <span className="mt-1">
                                                                <DropdownControl
                                                                    disabled={!isAccessible('deliveryEditChooseCar')}
                                                                    defaultColor="primary"
                                                                    dropdownName={t("Car")}
                                                                    useTranslate={false}
                                                                    arrayIDTextFormat={carData}
                                                                    valueID={carID}
                                                                    onSelectValueID={async (valueID) => {
                                                                        // For this button to update the text (value)
                                                                        setCarID(valueID);

                                                                        // Approach changed
                                                                        // Fast mode 
                                                                        // Just directly update the DB
                                                                        await updateCarIDToServer(valueID);

                                                                        // Get the data from DB
                                                                        // Refresh the whole form
                                                                        getDeliveryRecordFromDB();
                                                                    }}
                                                                />
                                                            </span>

                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDriver')}</span>
                                                            <span className="mt-1 ml-1">
                                                                <DropdownControl
                                                                    disabled={!isAccessible('deliveryEditChooseDriver')}
                                                                    defaultColor="primary"
                                                                    dropdownName={t("Driver")}
                                                                    useTranslate={false}
                                                                    arrayIDTextFormat={driverData}
                                                                    valueID={driverID}
                                                                    onSelectValueID={async (valueID) => {
                                                                        // For this button to update the text (value)
                                                                        setDriverID(valueID);

                                                                        // Approach changed
                                                                        // Fast mode 
                                                                        // Just directly update the DB
                                                                        await updateDriverIDToServer(valueID);

                                                                        // Get the data from DB
                                                                        // Refresh the whole form
                                                                        getDeliveryRecordFromDB();
                                                                    }}
                                                                />
                                                            </span>

                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-2">
                                                        <Col>

                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseTruckWorker')}</span>
                                                            <DeliveryTruckWorkerListButtonDropdownGroup
                                                                disabled={!isAccessible('deliveryEditChooseTruckWorker')}
                                                                DeliveryRecordTruckWorkerMatches={deliveryRecordTruckWorkerMatches}
                                                                getChosenTruckWorkerList={async (chosenTruckWorkerList) => {
                                                                    // chosenTruckWorkerList = [{id: 'asd', text: 'sffe', other: }, {id: 'asd', text: 'sffe', other: }, ...]
                                                                    if (chosenTruckWorkerList && Array.isArray(chosenTruckWorkerList) && chosenTruckWorkerList.length >= 0) {
                                                                        // truckWorkerIDs = [{truckWorkerID: 'aSDF'}, {truckWorkerID: 'ffddf'}, ...]
                                                                        let truckWorkerIDs = chosenTruckWorkerList.map((item) => {
                                                                            return { truckWorkerID: item.id };
                                                                        });


                                                                        // For this button to update the text (value)
                                                                        setDeliveryRecordTruckWorkerIDsToSubmit(truckWorkerIDs);

                                                                        // Approach changed
                                                                        // Fast mode 
                                                                        // Just directly update the DB
                                                                        await updateTruckWorkerListToServer(truckWorkerIDs);

                                                                        // Get the data from DB
                                                                        // Refresh the whole form
                                                                        getDeliveryRecordFromDB();
                                                                    }




                                                                }}
                                                            />

                                                        </Col>
                                                    </Row>


                                                    <span>{getAccessibleTagForSafeMode('deliveryEditEditProposedDateTime')}</span>
                                                    <Row className="mt-2 d-flex">
                                                        <Col>
                                                            <InputDateTimeGroupForm
                                                                className="flex-grow-1"
                                                                label=""
                                                                labelFor="proposedDateTime"
                                                                inputName="proposedDateTime"
                                                                inputId="proposedDateTime"
                                                                inputPlaceholder={t("Estimated Time")}
                                                                value={proposedDateTime}
                                                                onDateChange={async (date) => {
                                                                    // For this button to update the text (value)
                                                                    setproposedDateTime(date);

                                                                    // Ture on the update button
                                                                    setProposedDateTimeFastModeChanged(true);

                                                                }}
                                                                defaultDate={proposedDateTime}
                                                                readOnly={!isAccessible('deliveryEditEditProposedDateTime')}
                                                                // open={!isDriver}
                                                                showTimeBoo={true}
                                                                showCustomDateTimeButton={true}
                                                                specialButtonColor={"warning"}
                                                                useCheckButton={proposedDateTimeFastModeChanged}
                                                                checkButtonClick={async () => {
                                                                    // Ture off this button
                                                                    setProposedDateTimeFastModeChanged(false);

                                                                    // Approach changed
                                                                    // Fast mode 
                                                                    // Just directly update the DB
                                                                    await updateProposedDatetimeToServer(proposedDateTime);

                                                                    // Get the data from DB
                                                                    // Refresh the whole form
                                                                    getDeliveryRecordFromDB();
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-2 d-flex">
                                                        <Col>
                                                            <DeliveryAddressSelectInput
                                                                additionalAddressDataArray={additionalAddressDataArray}
                                                                preSelectAddressID={selectedAddressID}
                                                                returnSelectedAddressID={async (deliveryAddressID) => {
                                                                    if (selectedAddressID !== deliveryAddressID) {
                                                                        // chosen a different delivertAddressID

                                                                        // For this button to update the text (value)
                                                                        handleOnChangeDeliveryAddressID(deliveryAddressID);

                                                                        // Approach changed
                                                                        // Fast mode 
                                                                        // Just directly update the DB
                                                                        await updateDeliveryAddressIDToServer(deliveryAddressID);

                                                                        // Get the data from DB
                                                                        // Refresh the whole form
                                                                        getDeliveryRecordFromDB();
                                                                    }
                                                                }}
                                                                returnSelectedAddressData={(deliveryAddressData) => {
                                                                    // no need in fast mode
                                                                    handleOnChangeDeliveryAddressData(deliveryAddressData);
                                                                }}
                                                                showLabel={false}
                                                                reloadMe={reloadDeliveryAddressSelect}
                                                            />
                                                        </Col>
                                                    </Row>


                                                    <Row className="mb-1"
                                                        onClick={() => {
                                                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                        }}>
                                                        <Col>
                                                            <DeliveryAddressBadge
                                                                deliveryAddressData={selectedAddressData}
                                                                badgeType="address"
                                                                badgeClassName="statusBadge"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-1"
                                                        onClick={() => {
                                                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                        }}>

                                                        <Col>
                                                            <DeliveryAddressBadge
                                                                deliveryAddressData={selectedAddressData}
                                                                badgeType="relatedDeliveryRecordCount"
                                                                badgeClassName="statusBadge"
                                                            />
                                                            <DeliveryAddressBadge
                                                                deliveryAddressData={selectedAddressData}
                                                                badgeType="relatedDeliveryRecordMostUpdatedProposedDateTime"
                                                                badgeClassName="statusBadge"
                                                            />

                                                        </Col>
                                                    </Row>

                                                    {/*Delivery Address Button*/}
                                                    <Row className="d-flex mt-1">
                                                        <Col>
                                                            {isAccessible('deliveryEditNewAddress') &&
                                                                <>
                                                                    <span>{getAccessibleTagForSafeMode('deliveryEditNewAddress')}</span>
                                                                    {addressFastModeChanged &&
                                                                        <Button
                                                                            className="mr-1"
                                                                            color="success"
                                                                            onClick={async () => {
                                                                                // Approach changed
                                                                                // Fast mode 
                                                                                // Just directly update the DB
                                                                                await updateDeliveryAddressIDToServer(selectedAddressID);

                                                                                // Get the data from DB
                                                                                // Refresh the whole form
                                                                                getDeliveryRecordFromDB();

                                                                                // turn off this button
                                                                                setAddressFastModeChanged(false);

                                                                            }}>
                                                                            <i className="fas fa-check" />

                                                                        </Button>
                                                                    }
                                                                    <Button
                                                                        color="primary"
                                                                        id="tooltip443412082"
                                                                        onClick={async () => {
                                                                            await editDeliveryAddressProcess(selectedAddressID);
                                                                            // turn on the update button
                                                                            setAddressFastModeChanged(true);
                                                                        }} >
                                                                        <span className="btn-inner--icon mr-1">
                                                                            <i className="fas fa-pen" />
                                                                        </span>
                                                                        <span className="btn-inner--text">
                                                                            {t("Edit this Address")}
                                                                        </span>
                                                                    </Button>
                                                                </>


                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <InputTypeTextForm
                                                                label=""
                                                                labelFor="deliveryRemark"
                                                                inputName="deliveryRemark"
                                                                inputId="deliveryRemark"
                                                                inputPlaceholder={t("Delivery Remark")}
                                                                type="textarea"
                                                                value={deliveryRemark}
                                                                handleChange={(e) => {
                                                                    handleOnChangeDeliveryRemark(e.target.value);
                                                                    setDeliveryRemarkFastModeChanged(true);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-0">
                                                        <Col>
                                                            {deliveryRemarkFastModeChanged &&
                                                                <div className="d-flex">
                                                                    <Button
                                                                        className="flex-grow-1"
                                                                        color="success"
                                                                        onClick={async () => {
                                                                            // Approach changed
                                                                            // Fast mode 
                                                                            // Just directly update the DB
                                                                            await updateDeliveryRemarkToServer(deliveryRemark);

                                                                            // Get the data from DB
                                                                            // Refresh the whole form
                                                                            getDeliveryRecordFromDB();

                                                                            // turn off this button
                                                                            setDeliveryRemarkFastModeChanged(false);

                                                                        }}>
                                                                        <i className="fas fa-check" />
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>



                                                </CardHeader>

                                            </Card>



                                            {/* Control Button */}
                                            <Card>
                                                <CardBody >
                                                    <Row >
                                                        <Col className="w-100 d-flex justify-content-end">
                                                            <Button
                                                                color="default"
                                                                type="button"
                                                                onClick={() => {
                                                                    props.onHide();
                                                                }}
                                                            >
                                                                {t("Back")}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            {/* End of Control Button */}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {/* End Quick action */}



                            {/* Detail mode */}
                            {!showQuickActionSection && (
                                <Form>
                                    {/* Delivery Information */}
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div className="card-wrapper">
                                                <Card>
                                                    <CardHeader className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <Row className="ml-1">
                                                                <h3 className="mb-0">{t("Delivery Information")}</h3>
                                                            </Row>
                                                            <Row className="ml-1">
                                                                <DeliveryBadge
                                                                    deliveryRecordData={deliveryRecordData}
                                                                    badgeType="deliveryStatus"
                                                                    badgeClassName="statusBadge"
                                                                />
                                                                <DeliveryBadge
                                                                    deliveryRecordData={deliveryRecordData}
                                                                    badgeType="carID"
                                                                    badgeClassName="statusBadge"
                                                                />
                                                                <DeliveryBadge
                                                                    deliveryRecordData={deliveryRecordData}
                                                                    badgeType="driverID"
                                                                    badgeClassName="statusBadge"
                                                                />
                                                            </Row>
                                                            <Row className="ml-1 mt-1">
                                                                <DeliveryBadge
                                                                    deliveryRecordData={deliveryRecordData}
                                                                    badgeType="truckWorkerList"
                                                                    badgeClassName="statusBadge"
                                                                />
                                                            </Row>
                                                        </div>
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => setShowDeliverySection(!showDeliverySection)}
                                                            className="icon d-flex justify-content-end"
                                                        >
                                                            <i className={`fa fa-caret-${showDeliverySection ? "up" : "down"}`}
                                                            />
                                                        </div>
                                                    </CardHeader>

                                                    {showDeliverySection && (
                                                        <Card className="mb--2">
                                                            <CardHeader>
                                                                <Row className="mb-0">
                                                                    <Col>
                                                                        <h1>{`${deliverySubitemList.length}`}{t("items")}</h1>
                                                                    </Col>
                                                                    <Col
                                                                        className={"text-right"}
                                                                    >
                                                                        {isAccessible('deliveryEditAddProduct') &&
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    onAddProduct();
                                                                                }}
                                                                                size="sm"
                                                                            >
                                                                                <span className="btn-inner--icon mr-1">
                                                                                    <i className="fas fa-plus" />
                                                                                </span>
                                                                                <span className="btn-inner--text">
                                                                                    {t("Add")}
                                                                                </span>
                                                                                <span>{getAccessibleTagForSafeMode('deliveryEditAddProduct')}</span>
                                                                            </Button>
                                                                        }
                                                                    </Col>
                                                                </Row>

                                                            </CardHeader>
                                                            <CardBody className="pt-1">
                                                                <span>{getAccessibleTagForSafeMode('deliveryEditEditProduct')}</span>
                                                                <span>{getAccessibleTagForSafeMode('deliveryEditDeleteProduct')}</span>
                                                                <Row className="mb-2">
                                                                    <Col>
                                                                        <DeliverySubitemTable
                                                                            allowEditProduct={isAccessible('deliveryEditEditProduct')}
                                                                            allowDeleteProduct={isAccessible('deliveryEditDeleteProduct')}
                                                                            deliverySubitemsTable={deliverySubitemList} // 入去之前已經有 localDeliverySubitemID
                                                                            isDriver={isDriver}
                                                                            selectSubitem={(deliverySubitemItem) => {
                                                                                onEditProduct(deliverySubitemItem);
                                                                            }}
                                                                            deleteSubitem={(localDeliverySubitemID) => {
                                                                                onDeleteProduct(localDeliverySubitemID);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>




                                                            </CardBody>
                                                        </Card>
                                                    )}

                                                    {showDeliverySection && (
                                                        <CardBody className="mb-2">
                                                            {/*Basic delivery info part*/}
                                                            <Row>
                                                                <Col md={12} sm={12}>
                                                                    <Row className="">
                                                                        <Col>
                                                                            <div className="w-100 mb-4 d-flex justify-content-between">
                                                                                <div>
                                                                                    <Row>
                                                                                        <Col className="col-auto mt-1 pr-0">
                                                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryStatus')}</span>
                                                                                            <DropdownControl
                                                                                                disabled={!isAccessible('deliveryEditChooseDeliveryStatus')}
                                                                                                defaultColor="primary"
                                                                                                dropdownName={t("Delivery Status")}
                                                                                                useTranslate={true}
                                                                                                arrayIDTextFormat={deliveryStatusArray}
                                                                                                valueID={deliveryStatus}
                                                                                                onSelectValueID={(valueID) => {
                                                                                                    setDeliveryStatus(valueID);
                                                                                                    setDeliveryDetailEdited(true);
                                                                                                }}
                                                                                            />
                                                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryTeam')}</span>
                                                                                            <span className="mt-1 ml-1">
                                                                                                <Button
                                                                                                    disabled={!isAccessible('deliveryEditChooseDeliveryTeam')}
                                                                                                    color="warning"
                                                                                                    onClick={() => {
                                                                                                        setModalDeliveryTeamChooseModalIsOpen(true);
                                                                                                    }} >
                                                                                                    <span className="btn-inner--icon mr-1">
                                                                                                        <i className="fas fa-plus" />
                                                                                                    </span>
                                                                                                    <span className="btn-inner--text">
                                                                                                        {t("Team")}
                                                                                                    </span>
                                                                                                </Button>
                                                                                            </span>


                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col className="col-auto mt-1 pr-0">
                                                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseCar')}</span>
                                                                                            <DropdownControl
                                                                                                disabled={!isAccessible('deliveryEditChooseCar')}
                                                                                                defaultColor="primary"
                                                                                                dropdownName={t("Car")}
                                                                                                useTranslate={false}
                                                                                                arrayIDTextFormat={carData}
                                                                                                valueID={carID}
                                                                                                onSelectValueID={(valueID) => {
                                                                                                    setCarID(valueID);
                                                                                                    setDeliveryDetailEdited(true);
                                                                                                }}
                                                                                            />


                                                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseDriver')}</span>
                                                                                            <span className="ml-1">
                                                                                                <DropdownControl
                                                                                                    disabled={!isAccessible('deliveryEditChooseDriver')}
                                                                                                    defaultColor="primary"
                                                                                                    dropdownName={t("Driver")}
                                                                                                    useTranslate={false}
                                                                                                    arrayIDTextFormat={driverData}
                                                                                                    valueID={driverID}
                                                                                                    onSelectValueID={(valueID) => {
                                                                                                        setDriverID(valueID);
                                                                                                        setDeliveryDetailEdited(true);
                                                                                                    }}
                                                                                                />
                                                                                            </span>
                                                                                            {(driverID != "") &&
                                                                                                <Button
                                                                                                    className="ml-1 mr-0"
                                                                                                    color="success"
                                                                                                    onClick={() => {
                                                                                                        // Appending the phone number to the URL
                                                                                                        let url = `https://web.whatsapp.com/send?phone=${getDriverContact()}`;

                                                                                                        // Appending the message to the URL by encoding it
                                                                                                        //  url += `&text=${encodeURI(message)}&app_absent=0`;

                                                                                                        // Open our newly created URL in a new tab to send the message
                                                                                                        openPDFLink(url);

                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fab fa-whatsapp"></i>
                                                                                                </Button>
                                                                                            }

                                                                                            {(driverID != "") &&
                                                                                                <Button
                                                                                                    className=""
                                                                                                    color="success"
                                                                                                    onClick={() => {
                                                                                                        // Appending the phone number to the URL
                                                                                                        let url = `tel:+852-${getDriverContact()}`;

                                                                                                        // Appending the message to the URL by encoding it
                                                                                                        //  url += `&text=${encodeURI(message)}&app_absent=0`;

                                                                                                        // Open our newly created URL in a new tab to send the message
                                                                                                        openPDFLink(url);
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="fas fa-phone"></i>
                                                                                                </Button>
                                                                                            }

                                                                                        </Col>


                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col className="col-auto mt-1 pr-0">
                                                                                            <span>{getAccessibleTagForSafeMode('deliveryEditChooseTruckWorker')}</span>
                                                                                            <DeliveryTruckWorkerListButtonDropdownGroup
                                                                                                disabled={!isAccessible('deliveryEditChooseTruckWorker')}
                                                                                                DeliveryRecordTruckWorkerMatches={deliveryRecordTruckWorkerMatches}
                                                                                                getChosenTruckWorkerList={(chosenTruckWorkerList) => {
                                                                                                    setDeliveryRecordTruckWorkerIDsToSubmit(chosenTruckWorkerList);
                                                                                                    setDeliveryDetailEdited(true);
                                                                                                }}
                                                                                            />

                                                                                        </Col>
                                                                                    </Row>

                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    {/*Delivery Address part*/}
                                                                    {!isDriver && (
                                                                        <>
                                                                            <Row className="mt-0">
                                                                                <Col>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <DeliveryAddressSelectInput
                                                                                                additionalAddressDataArray={additionalAddressDataArray}
                                                                                                preSelectAddressID={selectedAddressID}
                                                                                                returnSelectedAddressID={(deliveryAddressID) => {
                                                                                                    handleOnChangeDeliveryAddressID(deliveryAddressID);
                                                                                                    if (selectedAddressID !== deliveryAddressID) {
                                                                                                        setDeliveryDetailEdited(true);
                                                                                                    }
                                                                                                }}
                                                                                                returnSelectedAddressData={(deliveryAddressData) => {
                                                                                                    handleOnChangeDeliveryAddressData(deliveryAddressData);
                                                                                                }}
                                                                                                showLabel={false}
                                                                                                reloadMe={reloadDeliveryAddressSelect}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row className="mb-1"
                                                                                        onClick={() => {
                                                                                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                                                        }}>
                                                                                        <Col>
                                                                                            <DeliveryAddressBadge
                                                                                                deliveryAddressData={selectedAddressData}
                                                                                                badgeType="address"
                                                                                                badgeClassName="statusBadge"
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row className="mb-1"
                                                                                        onClick={() => {
                                                                                            setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                                                        }}>

                                                                                        <Col>
                                                                                            <DeliveryAddressBadge
                                                                                                deliveryAddressData={selectedAddressData}
                                                                                                badgeType="relatedDeliveryRecordCount"
                                                                                                badgeClassName="statusBadge"
                                                                                            />
                                                                                            <DeliveryAddressBadge
                                                                                                deliveryAddressData={selectedAddressData}
                                                                                                badgeType="relatedDeliveryRecordMostUpdatedProposedDateTime"
                                                                                                badgeClassName="statusBadge"
                                                                                            />

                                                                                        </Col>
                                                                                    </Row>
                                                                                    {/*Delivery Address Button*/}
                                                                                    {/* <Row className="d-flex flex-row ml-1 mb-2 mt-1"> */}
                                                                                    <Row className="">
                                                                                        <Col>
                                                                                            {isAccessible('deliveryEditNewAddress') &&
                                                                                                <>
                                                                                                    <Button
                                                                                                        color="primary"
                                                                                                        id="tooltip443412082"
                                                                                                        onClick={async () => {
                                                                                                            setDeliveryDetailEdited(true);
                                                                                                            await editDeliveryAddressProcess(selectedAddressID);
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="btn-inner--icon mr-1">
                                                                                                            <i className="fas fa-pen" />
                                                                                                        </span>
                                                                                                        <span className="btn-inner--text">
                                                                                                            {t("Edit this Address")}
                                                                                                        </span>
                                                                                                        <span>{getAccessibleTagForSafeMode('deliveryEditNewAddress')}</span>
                                                                                                    </Button>
                                                                                                </>
                                                                                            }
                                                                                        </Col>
                                                                                    </Row>

                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                    )}



                                                                    <Row className="mt-2 d-flex align-items-center ">
                                                                        <span>{getAccessibleTagForSafeMode('deliveryEditEditProposedDateTime')}</span>
                                                                        <Col md={4}>
                                                                            <Label className="form-control-label">
                                                                                {t("Estimated Time")}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <InputDateTimeGroupForm
                                                                                label=""
                                                                                labelFor="proposedDateTime"
                                                                                inputName="proposedDateTime"
                                                                                inputId="proposedDateTime"
                                                                                inputPlaceholder={t("Estimated Time")}
                                                                                value={proposedDateTime}
                                                                                onDateChange={(date) => {
                                                                                    setproposedDateTime(date);
                                                                                    setDeliveryDetailEdited(true);
                                                                                }}
                                                                                defaultDate={proposedDateTime}
                                                                                readOnly={!isAccessible('deliveryEditEditProposedDateTime')}
                                                                                // open={!isDriver}
                                                                                showTimeBoo={true}
                                                                                showCustomDateTimeButton={true}
                                                                                specialButtonColor={"warning"}
                                                                            />
                                                                        </Col>
                                                                    </Row>


                                                                    <Row className="mt-2 d-flex align-items-center ">
                                                                        <Col md={4}>
                                                                            <Label className="form-control-label">
                                                                                {t("Remark")}
                                                                            </Label>
                                                                        </Col>

                                                                        <Col className="">
                                                                            <InputTypeTextForm
                                                                                label=""
                                                                                labelFor="deliveryRemark"
                                                                                inputName="deliveryRemark"
                                                                                inputId="deliveryRemark"
                                                                                inputPlaceholder={t("Delivery Remark")}
                                                                                type="textarea"
                                                                                value={deliveryRemark}
                                                                                handleChange={(e) => {
                                                                                    handleOnChangeDeliveryRemark(e.target.value);
                                                                                    setDeliveryDetailEdited(true);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                                <Col>

                                                                    <Row className="mt-2 d-flex align-items-center ">
                                                                        <Col md={4}>
                                                                            <Label className="form-control-label">
                                                                                {t("Record DateTime")}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <InputDateTimeGroupForm
                                                                                label=""
                                                                                labelFor="deliveryRecordDateTime"
                                                                                inputName="deliveryRecordDateTime"
                                                                                inputId="deliveryRecordDateTime"
                                                                                inputPlaceholder={t("Record DateTime")}
                                                                                value={deliveryRecordDateTime}
                                                                                onDateChange={(date) => {
                                                                                    setDeliveryRecordDateTime(date);
                                                                                    setDeliveryDetailEdited(true);
                                                                                }}
                                                                                defaultDate={deliveryRecordDateTime}
                                                                                readOnly
                                                                                showTimeBoo={true}
                                                                                showCustomDateTimeButton={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-2 d-flex align-items-center ">
                                                                        <Col md={4}>
                                                                            <Label className="form-control-label">
                                                                                {t("Start DateTime")}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <InputDateTimeGroupForm
                                                                                label=""
                                                                                labelFor="startDeliveryDateTime"
                                                                                inputName="startDeliveryDateTime"
                                                                                inputId="startDeliveryDateTime"
                                                                                inputPlaceholder={t("Start DateTime")}
                                                                                value={startDeliveryDateTime}
                                                                                onDateChange={(date) => {
                                                                                    setStartDeliveryDateTime(date);
                                                                                    setDeliveryDetailEdited(true);
                                                                                }}
                                                                                defaultDate={startDeliveryDateTime}
                                                                                readOnly
                                                                                showTimeBoo={true}
                                                                                showCustomDateTimeButton={true}
                                                                            // open={!isDriver}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-2 d-flex align-items-center">
                                                                        <Col md={4}>
                                                                            <Label className="form-control-label">
                                                                                {t("Finish DateTime")}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col>
                                                                            <InputDateTimeGroupForm
                                                                                label=""
                                                                                labelFor="finishDateTime"
                                                                                inputName="finishDateTime"
                                                                                inputId="finishDateTime"
                                                                                inputPlaceholder={t("Finish DateTime")}
                                                                                value={finishDateTime}
                                                                                onDateChange={(date) => {
                                                                                    setFinishDateTime(date);
                                                                                    setDeliveryDetailEdited(true);
                                                                                }}
                                                                                defaultDate={finishDateTime}
                                                                                readOnly={true}
                                                                                showTimeBoo={true}
                                                                                showCustomDateTimeButton={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="d-flex flex-column align-items-center">
                                                                        <QRCode
                                                                            onClick={() =>
                                                                                openPDFLink(props.deliveryData?.DNURL)
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                            className="mt-5"
                                                                            value={props.deliveryData?.DNURL ? props.deliveryData?.DNURL : ""}
                                                                            size={200}
                                                                        />
                                                                        <div
                                                                            onClick={() =>
                                                                                openPDFLink(props.deliveryData?.DNURL)
                                                                            }
                                                                            style={{ cursor: "pointer" }}
                                                                            className="text-sm text-underline mt-2"
                                                                        >
                                                                            {t("Click to view")}
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                            </Row>


                                                        </CardBody>
                                                    )}
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* End Delivery Information */}

                                    {/* Proof Section */}
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div className="card-wrapper">
                                                <Card>
                                                    <CardHeader className="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <Row className="ml-1">
                                                                <h3 className="mb-0">{t("Proof of Delivery")}</h3>
                                                            </Row>
                                                            <Row className="ml-1">
                                                                <Badge
                                                                    color={signature === "" ? "danger" : "success"}
                                                                    className={"statusBadge"}
                                                                >
                                                                    <i className={signature === "" ? "fas fa-times" : "fas fa-check"} />
                                                                    {" "}{t("sign")}
                                                                </Badge>
                                                                <Badge
                                                                    color={countGridPhoto() > 0 ? "success" : "danger"}
                                                                    className={"statusBadge"}
                                                                >
                                                                    {countGridPhoto()}{" "}{t("pic")}
                                                                </Badge>
                                                            </Row>
                                                        </div>
                                                        <div className="icon d-flex justify-content-end">
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setShowProofSection(!showProofSection)}
                                                                className="icon d-flex justify-content-end mt-2"
                                                            >
                                                                <i className={`fa fa-caret-${showProofSection ? "up" : "down"}`} />
                                                            </div>
                                                        </div>

                                                    </CardHeader>
                                                    {showProofSection && (
                                                        <CardBody className="mb-5">
                                                            <Row className="mt-2">
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <DeliveryRecordSignature
                                                                        useTranslate={true}
                                                                        signature={signature}
                                                                        signatureRecordDateTime={signatureRecordDateTime}
                                                                        signatureUploadUser={signatureUploadUser}
                                                                        signatureUploadUserNickName={signatureUploadUserNickName}
                                                                        onOpenSignature={() => { setSignatureIsOpen(true) }}
                                                                        onDeleteSignature={() => {
                                                                            onChangeSignatureData("", new Date(), ""); // for this page to refresh
                                                                            deleteSignatureNow();
                                                                        }} />
                                                                </Col>

                                                                <DeliveryRecordSignatureModal
                                                                    isOpen={signatureIsOpen}
                                                                    toggle={() => setSignatureIsOpen(false)}
                                                                    onSubmit={async (dataUrl) => {
                                                                        let imageBase64 = dataUrl.split(",")[1];
                                                                        onChangeSignatureData(imageBase64, new Date()); // for this page to refresh
                                                                        uploadSignatureNow(imageBase64); // for upload
                                                                        setSignatureIsOpen(false);
                                                                    }}
                                                                />
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <PhotoUploadArea
                                                                        title={t("Photo Upload")}
                                                                        useTranslate={true}
                                                                        isShow={showProofSection}
                                                                        allowPhotoRemark={true}
                                                                        photoID={photo1ID}
                                                                        photoBase64Data={photo1Base64Content}
                                                                        photoRemark={photo1Remark}
                                                                        photoOrder={photo1Order}
                                                                        photoUserID={photo1UploadUserID}
                                                                        photoUserNickName={photo1UploadUserNickName}
                                                                        photoRecordDateTime={photo1ImageUploadTime}
                                                                        onUploadPhoto={async (photoDataToUpload, photoRemark) => {
                                                                            let returnPhotoID = await uploadImgNowReturnPhotoID(photoDataToUpload, photoRemark, "1");
                                                                            onChangeDeliveryPhoto1(
                                                                                returnPhotoID,
                                                                                await getBase64PhotoHeadRemoved(photoDataToUpload),
                                                                                photoRemark,
                                                                                "1",
                                                                                Date(),
                                                                                getUserId(),
                                                                                getNickname()
                                                                            )
                                                                        }}
                                                                        onDownloadPhoto={(photoID, photoBase64Content) => {
                                                                            downloadImg(photoID, photoBase64Content);
                                                                        }}
                                                                        onDeletePhoto={(photoID) => {
                                                                            deleteImgNow(photoID);
                                                                            onChangeDeliveryPhoto1("", "", "", "1", Date(), "", "");
                                                                        }}
                                                                        onUpdatePhotoData={async (photoID, photoRemark) => {
                                                                            let returnPhotoID = await updateImgDataNowReturnPhotoID(photoID, photoRemark, "1");
                                                                        }}
                                                                    />
                                                                </Col>



                                                                {gridPhoto >= 2 || photo2ID !== "" ? (
                                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                                        <PhotoUploadArea
                                                                            title={t("Photo Upload")}
                                                                            useTranslate={true}
                                                                            isShow={showProofSection}
                                                                            allowPhotoRemark={true}
                                                                            photoID={photo2ID}
                                                                            photoBase64Data={photo2Base64Content}
                                                                            photoRemark={photo2Remark}
                                                                            photoOrder={photo2Order}
                                                                            photoUserID={photo2UploadUserID}
                                                                            photoUserNickName={photo2UploadUserNickName}
                                                                            photoRecordDateTime={photo2ImageUploadTime}
                                                                            onUploadPhoto={async (photoDataToUpload, photoRemark) => {
                                                                                let returnPhotoID = await uploadImgNowReturnPhotoID(photoDataToUpload, photoRemark, "2");
                                                                                onChangeDeliveryPhoto2(
                                                                                    returnPhotoID,
                                                                                    await getBase64PhotoHeadRemoved(photoDataToUpload),
                                                                                    photoRemark,
                                                                                    "2",
                                                                                    Date(),
                                                                                    getUserId(),
                                                                                    getNickname()
                                                                                )
                                                                            }}
                                                                            onDownloadPhoto={(photoID, photoBase64Content) => {
                                                                                downloadImg(photoID, photoBase64Content);
                                                                            }}
                                                                            onDeletePhoto={(photoID) => {
                                                                                deleteImgNow(photoID);
                                                                                onChangeDeliveryPhoto2("", "", "", "2", Date(), "", "")
                                                                            }}
                                                                            onUpdatePhotoData={async (photoID, photoRemark) => {
                                                                                let returnPhotoID = await updateImgDataNowReturnPhotoID(photoID, photoRemark, "2");
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                {gridPhoto >= 3 || photo3ID !== "" ? (
                                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                                        <PhotoUploadArea
                                                                            title={t("Photo Upload")}
                                                                            useTranslate={true}
                                                                            isShow={showProofSection}
                                                                            allowPhotoRemark={true}
                                                                            photoID={photo3ID}
                                                                            photoBase64Data={photo3Base64Content}
                                                                            photoRemark={photo3Remark}
                                                                            photoOrder={photo3Order}
                                                                            photoUserID={photo3UploadUserID}
                                                                            photoUserNickName={photo3UploadUserNickName}
                                                                            photoRecordDateTime={photo3ImageUploadTime}
                                                                            onUploadPhoto={async (photoDataToUpload, photoRemark) => {
                                                                                let returnPhotoID = await uploadImgNowReturnPhotoID(photoDataToUpload, photoRemark, "3");
                                                                                onChangeDeliveryPhoto3(
                                                                                    returnPhotoID,
                                                                                    await getBase64PhotoHeadRemoved(photoDataToUpload),
                                                                                    photoRemark,
                                                                                    "3",
                                                                                    Date(),
                                                                                    getUserId(),
                                                                                    getNickname()
                                                                                )
                                                                            }}
                                                                            onDownloadPhoto={(photoID, photoBase64Content) => {
                                                                                downloadImg(photoID, photoBase64Content);
                                                                            }}
                                                                            onDeletePhoto={(photoID) => {
                                                                                deleteImgNow(photoID);
                                                                                onChangeDeliveryPhoto3("", "", "", "3", Date(), "", "")
                                                                            }}
                                                                            onUpdatePhotoData={async (photoID, photoRemark) => {
                                                                                let returnPhotoID = await updateImgDataNowReturnPhotoID(photoID, photoRemark, "3");
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                ) : (
                                                                    <></>
                                                                )}


                                                                {gridPhoto >= 4 || photo4ID !== "" ? (
                                                                    <Col xs={12} sm={12} md={12} lg={12}>
                                                                        <PhotoUploadArea
                                                                            title={t("Photo Upload")}
                                                                            useTranslate={true}
                                                                            isShow={showProofSection}
                                                                            allowPhotoRemark={true}
                                                                            photoID={photo4ID}
                                                                            photoBase64Data={photo4Base64Content}
                                                                            photoRemark={photo4Remark}
                                                                            photoOrder={photo4Order}
                                                                            photoUserID={photo4UploadUserID}
                                                                            photoUserNickName={photo4UploadUserNickName}
                                                                            photoRecordDateTime={photo4ImageUploadTime}
                                                                            onUploadPhoto={async (photoDataToUpload, photoRemark) => {
                                                                                let returnPhotoID = await uploadImgNowReturnPhotoID(photoDataToUpload, photoRemark, "4");
                                                                                onChangeDeliveryPhoto4(
                                                                                    returnPhotoID,
                                                                                    await getBase64PhotoHeadRemoved(photoDataToUpload),
                                                                                    photoRemark,
                                                                                    "4",
                                                                                    Date(),
                                                                                    getUserId(),
                                                                                    getNickname()
                                                                                )
                                                                            }}
                                                                            onDownloadPhoto={(photoID, photoBase64Content) => {
                                                                                downloadImg(photoID, photoBase64Content);
                                                                            }}
                                                                            onDeletePhoto={(photoID) => {
                                                                                deleteImgNow(photoID);
                                                                                onChangeDeliveryPhoto4("", "", "", "4", Date(), "", "");
                                                                            }}
                                                                            onUpdatePhotoData={async (photoID, photoRemark) => {
                                                                                let returnPhotoID = await updateImgDataNowReturnPhotoID(photoID, photoRemark, "4");
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                {gridPhoto < 4 && (
                                                                    <Col
                                                                        className="d-flex flex-column"
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                        lg={12}
                                                                    >
                                                                        <Button
                                                                            //  className="btnAddPhotoDo"
                                                                            color="primary"
                                                                            className=""
                                                                            onClick={() => {
                                                                                setGridPhoto(gridPhoto + 1);
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-download lg" />
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                        </CardBody>
                                                    )}
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* End Proof Section */}

                                    {/* Customer Information */}
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <div className="card-wrapper">
                                                <Card className="mb-4">

                                                    <CardHeader className="d-flex justify-content-between align-items-center">

                                                        <div>
                                                            <Row className="ml-1">
                                                                <h3 className="mb-0">{t("Customer Name")}</h3>
                                                            </Row>
                                                            <Row className="ml-1">
                                                                <InvoiceBadge
                                                                    invoiceData={displayInvoiceData}
                                                                    badgeType="customerName"
                                                                    badgeClassName="statusBadge"
                                                                />
                                                            </Row>


                                                        </div>



                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setShowCustomerSection(!showCustomerSection);
                                                            }}
                                                            className="icon d-flex justify-content-end"
                                                        >
                                                            <i
                                                                className={`fa fa-caret-${showCustomerSection ? "up" : "down"
                                                                    }`}
                                                            />
                                                        </div>

                                                    </CardHeader>
                                                    {showCustomerSection && (

                                                        <>
                                                            {Object.keys(customerSelectedData)?.length > 0 ? (
                                                                <>
                                                                    <Row className="p-4">
                                                                        <Col md="12">
                                                                            <InputTypeTextForm
                                                                                value={
                                                                                    customerSelectedData.customerName ??
                                                                                    "Customer Name"
                                                                                }
                                                                                label={t("Customer")}
                                                                                disabled={true}
                                                                                readOnly={true}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="px-4">
                                                                        <Col >

                                                                            <CustomerDisplayCard
                                                                                customerData={customerSelectedData}
                                                                                hideCardHeader={true} />
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>

                                                    )}
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* End Customer Information */}

                                    {/* Invoice Information */}
                                    {isAccessible('deliveryEditShowInvoice') &&
                                        <Row>
                                            <Col sm="12" lg="12">
                                                <div className="card-wrapper">
                                                    <span>{getAccessibleTagForSafeMode('deliveryEditShowInvoice')}</span>
                                                    <Card className="mb-4">
                                                        <CardHeader className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <Row className="ml-1">
                                                                    <h3 className="mb-0">{t("Invoice Detail")}</h3>
                                                                </Row>
                                                                <Row className="ml-1">
                                                                    <InvoiceBadge
                                                                        invoiceData={displayInvoiceData}
                                                                        badgeType="invoiceStatus"
                                                                        badgeClassName="statusBadge"
                                                                    />
                                                                    <InvoiceBadge
                                                                        invoiceData={displayInvoiceData}
                                                                        badgeType="invoiceNetPrice"
                                                                        badgeClassName="statusBadge"
                                                                    />
                                                                    <InvoiceBadge
                                                                        invoiceData={displayInvoiceData}
                                                                        badgeType="customerName"
                                                                        badgeClassName="statusBadge"
                                                                    />
                                                                </Row>

                                                            </div>



                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => setShowInvoiceSection(!showInvoiceSection)}
                                                                className="icon d-flex justify-content-end"
                                                            >
                                                                <i
                                                                    className={`fa fa-caret-${showInvoiceSection ? "up" : "down"
                                                                        }`}
                                                                />
                                                            </div>

                                                        </CardHeader>
                                                        {showInvoiceSection && (
                                                            <>
                                                                {displayInvoiceData && Object.keys(displayInvoiceData)?.length > 0 ? (

                                                                    <InvoiceCard
                                                                        styleSkipBottomSpaceBoo={true}
                                                                        invoiceData={displayInvoiceData}
                                                                        setClickViewInvoiceButton={(invoice) => {
                                                                            setInvoiceSelectedDataForInvoiceMessage(invoice);
                                                                            setModalInvoiceMessageIsOpen(true);
                                                                        }}
                                                                        onClickCard={(invoice) => {
                                                                            editInvoice(invoice);
                                                                        }}
                                                                        isSelectedCard={false}
                                                                        cloneInvoice={(invoice) => {
                                                                            setModalInvoiceCreateIsOpen(true);


                                                                        }}
                                                                        accessCustomerAction={true}
                                                                        customerAction={(customer) => {
                                                                            setCustomerSelectedDataForCustomerAction(customer);
                                                                            setModalCustomerActionIsOpen(true);
                                                                        }}

                                                                        accessCloneInvoice={true}
                                                                    />

                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </>
                                                        )}

                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {/* End Invoice Information */}

                                    {/* Control Button */}
                                    <Card>
                                        <CardBody className="px-2">
                                            <div className={isAccessible('deliveryRecordEditShowDeleteDeliveryRecordButton') ? "w-100 d-flex justify-content-between" : "w-100 d-flex justify-content-end"} >
                                                {isAccessible('deliveryRecordEditShowDeleteDeliveryRecordButton') && deliveryStatus != "" &&
                                                    <div>
                                                        <Button
                                                            disabled={deliveryStatus != "cancel"}
                                                            color="danger"
                                                            type="button" // type="button" means the form will not submit
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteConfirmationDO(deliveryID);
                                                            }}
                                                        >
                                                            {t("Delete delivery record")}
                                                            <span>{getAccessibleTagForSafeMode('deliveryRecordEditShowDeleteDeliveryRecordButton')}</span>
                                                        </Button>
                                                    </div>
                                                }
                                                <div>
                                                    <Button
                                                        color={"success"}
                                                        type="submit" // type="submit" means the form calls onSubmit
                                                        id="btnUpdate"
                                                        name="btnUpdate"
                                                        disabled={!deliveryDetailEdited}
                                                        onClick={async (e) => {
                                                            await updateDo(e);
                                                            getDeliveryRecordFromDB();
                                                            setDeliveryDetailEdited(false);
                                                        }}
                                                    >

                                                        {t("Save")}
                                                    </Button>
                                                    <Button
                                                        color="default"
                                                        type="button"
                                                        onClick={() => {
                                                            props.onHide();
                                                        }}
                                                    >
                                                        {t("Back")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    {/* End of Control Button */}
                                </Form>
                            )
                            }

                        </Col>
                    </Row>
                </ModalBody >

                <ModalFooter>
                    {/* Nothing here */}
                </ModalFooter>
            </Modal >


            <DeliveryRecordAddProductModal
                show={modalAddProduct}
                onHide={() => setModalAddProduct(false)}
                title={t("Add Delivery Items")}
                button={t("Save")}
                invoiceData={displayInvoiceData}
                addToDeliverySubitemsTable={(element) => {
                    addItemArrayToTable(new Array(element));
                }}
            />
            <DeliveryRecordEditProductModal
                show={modalEditProduct}
                onHide={() => setModalEditProduct(false)}
                title={t("Edit Product Items")}
                button={t("Update")}
                invoiceData={displayInvoiceData}
                selectedSubitem={selectedDeliverySubitem}
                addToDeliverySubitemsTable={(element) => {
                    editItemInTable(element);
                }}
            />
            <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={customerSelectedDataForCustomerAction}
                refreshTable={() => { setRefreshTable(refreshTable + 1) }}
            />
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={invoiceSelectedDataForInvoiceMessage}
            />
            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    setRefreshTable(refreshTable + 1);
                }}
                invoiceData={invoiceSelectedDataForInvoiceEdit}
                trafficControlDeliveryIDToProhibit={deliveryID} // traffic control is to prohibit data corruption
            />
            <InvoiceCreateModal
                invoiceToClone={displayInvoiceData}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                }}
                allowEmptySubitemList={true}
            />
            <DeliveryRecordMessageModal
                show={modalDeliveryRecordMessageIsOpen}
                onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
                title={t("Delivery Message")}
                refreshTable={() => { }}
                deliveryRecordData={deliveryRecordData}
            />

            <DeliveryRecordCreateModal
                show={modalDeliveryRecordAddIsOpen}
                invoiceData={deliveryRecordData?.Invoice}
                deliveryRecordToClone={deliveryRecordData}
                onHide={() => {
                    setModalDeliveryRecordAddIsOpen(false);
                    props.onHide();
                }}
            />
            <AddressModalEdit
                show={modalDeliveryAddressEditIsOpen}
                onHide={() => setModalDeliveryAddressEditIsOpen(false)}
                title={t("Edit address")}
                button={t("Update")}
                refreshTable={() => { setReloadDeliveryAddressSelect(true); }}
                deliveryAddressToClone={{ deliveryAddressID: selectedAddressID, ...selectedAddressData }}
                specialMessage={specialMessageForAddressEditProcess}
            />
            <AddressModalCreate
                show={modalCreateDeliveryAddressIsOpen}
                onHide={(e) => {
                    setModalCreateDeliveryAddressIsOpen(false);
                }}
                title={t("Create new address")}
                button={t("Add")}
                refreshTable={() => { }}
                deliveryAddressToClone={{}}
                getNewAddressData={(newAddressData) => {
                    setAdditionalAddressDataArray([newAddressData]);
                    setSelectedAddressID(newAddressData.deliveryAddressID);
                }}
                specialMessage={specialMessageForAddressEditProcess}
            />

            <DeliveryTeamChooseModal
                show={modalDeliveryTeamChooseModalIsOpen}
                onHide={() => setModalDeliveryTeamChooseModalIsOpen(false)}
                title={t("Choose a delivery team")}
                button={t("Confirm")}
                returnSingleDeliveryTeam={async (deliveryTeamToReturn) => {

                    setCarID(deliveryTeamToReturn.carID);
                    setDriverID(deliveryTeamToReturn.driverID);
                    if (showQuickActionSection) {
                        // fast mode
                        // directly update
                        await updateCarIDToServer(deliveryTeamToReturn.carID);
                        await updateDriverIDToServer(deliveryTeamToReturn.driverID);
                    }

                    if (deliveryTeamToReturn.DeliveryTeamTruckWorkerMatches && Array.isArray(deliveryTeamToReturn.DeliveryTeamTruckWorkerMatches) && deliveryTeamToReturn.DeliveryTeamTruckWorkerMatches.length >= 0) {
                        let truckWorkerIDs = deliveryTeamToReturn.DeliveryTeamTruckWorkerMatches.map((item) => {
                            return { truckWorkerID: item.truckWorkerID }
                        })
                        // This is for GUI data
                        setDeliveryRecordTruckWorkerMatches(deliveryTeamToReturn.DeliveryTeamTruckWorkerMatches);
                        // This is for POST data
                        setDeliveryRecordTruckWorkerIDsToSubmit(truckWorkerIDs);

                        if (showQuickActionSection) {
                            // fast mode
                            // directly update
                            await updateTruckWorkerListToServer(truckWorkerIDs);
                        }
                    }


                    if (showQuickActionSection) {
                        // fast mode
                        // directly update
                    } else {
                        // detail mode
                        setDeliveryDetailEdited(true);
                    }

                }}
            />

            <AddressRelatedDeliveryRecord
                show={modalAddressRelatedDeliveryRecordIsOpen}
                onHide={() => setModalAddressRelatedDeliveryRecordIsOpen(false)}
                preSelectAddressID={selectedAddressData.deliveryAddressID}
                canChooseAddress={false}
            />
        </>
    );
}

export default DeliveryRecordEditModal;

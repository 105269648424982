import React, { useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { getSingleInvoice } from "../../../functions/Invoice"
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";
import { getDateString } from "functions/Utility";


//props:
//deliveryRecordData
//onClickCard
//isSelectedCard
//cloneInvoice
//customerAction
//setClickViewInvoiceButton
//showDeliveryMessage

function DeliveryRecordCard(props) {
    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;
    const [whiteText, setWhiteText] = React.useState("");
    const [deliveryRecord, setDeliveryRecord] = React.useState({});

    const { addOrDeleteDeliveryRecordToGlobalList } = props;

    React.useEffect(() => {
        setWhiteText(" " + (props.enableCardBgColor ? "cardWhiteText" : ""));
    }, [props.enableCardBgColor]);

    React.useEffect(() => {

        if (props.deliveryRecordData) {
            // if ( props.deliveryRecordData.DeliveryRecordSubitems && Array.isArray( props.deliveryRecordData.DeliveryRecordSubitems)) {
            //     props.deliveryRecordData.DeliveryRecordSubitems.forEach(element => {
            //     });
            // }
            setDeliveryRecord(props.deliveryRecordData);
        }
    }, [props.deliveryRecordData]);

    async function cloneInvoiceConfirmation(invoiceID) {
        let alert = {
            type: "cloneInvoiceConfirmation",
            title: "Are you Sure?",
            text: t("Ready to clone an invoice."),
            dataToPass: invoiceID,
            callback: cloneInvoice,
        };
        Notifications(alert);
    }

    async function cloneInvoice(invoiceID) {
        // actually get the data of the invoice-to-clone when it is trully needs to clone
        const invoiceToClone = await getSingleInvoice(invoiceID);
        props.cloneInvoice(invoiceToClone);
    }

    function cloneDeliveryRecordConfirmation(deliveryRecordToClone) {
        let alert = {
            type: "cloneDeliveryRecordConfirmation",
            title: "Are you Sure?",
            text: t("Ready to clone a delivery record"),
            dataToPass: deliveryRecordToClone,
            callback: cloneDeliveryRecord,
        };
        Notifications(alert);
    }

    function cloneDeliveryRecord(deliveryRecordToClone) {
        props.cloneDeliveryRecord(deliveryRecordToClone);
    }

    function showDeliveryMessage(deliveryRecordToShow) {
        props.showDeliveryMessage(deliveryRecordToShow);
    }


    const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;



    const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card

    React.useEffect(() => {
        setShowFullCard(props.showFullCard);
    }, [props.showFullCard]);

    return deliveryRecord ? (
        <Card
            key={deliveryRecord?.deliveryID}
            style={{ cursor: "pointer" }}
        >
            <CardHeader className={props.enableCardBgColor ? props.cardBgColor : ""}>
                <div className={"d-flex justify-content-between" + whiteText}>
                    <div className="ml--1">
                        <Button
                            className="mb-1"
                            color="primary"
                            size="sm"
                            onClick={() => { setShowFullCard(!showFullCard) }}>
                            <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                        </Button>
                        <DeliveryBadge
                            deliveryRecordData={deliveryRecord}
                            badgeType="deliveryStatus"
                            badgeClassName="statusBadge"
                        />
                        <DeliveryBadge
                            deliveryRecordData={deliveryRecord}
                            badgeType="carID"
                            badgeClassName="statusBadge"
                        />
                        <DeliveryBadge
                            deliveryRecordData={deliveryRecord}
                            badgeType="driverID"
                            badgeClassName="statusBadge"
                        />
                        <DeliveryBadge
                            deliveryRecordData={deliveryRecord}
                            badgeType="truckWorkerList"
                            badgeClassName="statusBadge"
                        />
                    </div>
                    <div className="cardIconButton">

                        <Badge color={"info"} className="badge-lg text-lg badge-circle">
                            <i className="ni ni-ungroup"
                                id={`tooltipCloneDR${deliveryRecord?.deliveryID}`}
                                onClick={(e) => {
                                    cloneDeliveryRecordConfirmation(deliveryRecord);
                                }} />

                        </Badge>


                        <span>&nbsp;</span>
                        <Badge color={"info"} className="badge-lg text-lg badge-circle">
                            <i className="ni ni-credit-card"
                                id={`tooltipDNURL${deliveryRecord?.deliveryID}`}
                                onClick={(e) => {
                                    showDeliveryMessage(deliveryRecord);
                                }} />
                        </Badge>

                        <span>&nbsp;</span>

                        <Badge color={deliveryRecord.isExistInGlobalList ? "success" : "danger"} className="badge-lg text-lg badge-circle">
                            {deliveryRecord.isExistInGlobalList ?
                                <i className="ni ni-check-bold"
                                    onClick={(e) => {
                                        addOrDeleteDeliveryRecordToGlobalList(deliveryRecord);
                                    }} />
                                :
                                <i className="ni ni-fat-add"
                                    onClick={(e) => {
                                        addOrDeleteDeliveryRecordToGlobalList(deliveryRecord);
                                    }} />
                            }

                        </Badge>

                    </div>
                </div>
                <Row onClick={(e) => {
                    e.preventDefault();
                    props.onClickCard(deliveryRecord);
                }}>
                    <Col xs={12} >
                        <div className={"tableRowTitle" + whiteText}>
                            {combineAddress(deliveryRecord.DeliveryAddress)}
                        </div>
                        <div className={"font-weight-bold" + whiteText}>
                            {t("Estimated Time")}:
                            {`${getDateString(deliveryRecord.proposedDateTime, activeLang === "cn", false)}`}
                        </div>
                        <div className="font-weight-bold tableRowRemark">
                            {deliveryRecord.deliveryRemark}
                        </div>
                        <div className={"tableRowSubTitleMain" + whiteText}>
                            {deliveryRecord?.Invoice?.Customer?.customerName === undefined
                                ? "-"
                                : deliveryRecord?.Invoice?.Customer.customerName}
                        </div>
                        <div className={"tableRowSubTitle" + whiteText}>
                            {(deliveryRecord?.Invoice?.Customer && deliveryRecord?.Invoice?.Customer?.CustomerPhones && deliveryRecord?.Invoice?.Customer?.CustomerPhones.length >= 1)
                                ? `${deliveryRecord?.Invoice?.Customer?.CustomerPhones[0]?.phoneUserName}:${deliveryRecord?.Invoice?.Customer?.CustomerPhones[0]?.phoneNumber}`
                                : t("No contact")}
                        </div>

                    </Col>
                </Row>
                {(deliveryRecord?.DeliveryRecordSubitems) && (Object.values(deliveryRecord?.DeliveryRecordSubitems).length > 0) &&
                    <Row>
                        <Col onClick={(e) => {
                            e.preventDefault();
                            props.onClickCard(deliveryRecord);
                        }}  >
                            {deliveryRecord?.DeliveryRecordSubitems?.map((deliveryRecordSubitem, index) => (

                                <span key={index} className={whiteText}>

                                    <Badge
                                        color={"warning"}
                                        className="statusBadge"
                                    >

                                        {deliveryRecordSubitem?.deliverySubitemQuantity}{deliveryRecordSubitem?.Good?.goodUnit}{deliveryRecordSubitem?.Good?.goodNameShortForm}

                                    </Badge>

                                    {deliveryRecordSubitem?.deliverySubitemRemark !== "" &&
                                        <Badge
                                            color={"default"}
                                            className="statusBadge"
                                        >

                                            #{deliveryRecordSubitem?.deliverySubitemRemark}

                                        </Badge>

                                    }
                                </span>
                            ))}
                        </Col>
                    </Row>
                }
                {(deliveryRecord?.DeliveryRecordSubitems) && (Object.values(deliveryRecord?.DeliveryRecordSubitems).length <= 0) &&
                    <Row>
                        <Col onClick={(e) => {
                            e.preventDefault();
                            props.onClickCard(deliveryRecord);
                        }}  >
                            <div className={`tableRowSecondTitle` + ' ' + whiteText}>
                                <span>{t("Empty Delivery Items")}</span>
                            </div>


                        </Col>
                    </Row>
                }
            </CardHeader>

            {showFullCard &&
                <CardBody className={props.enableCardBgColor ? props.cardBgColor : ""}>
                    <Row >
                        <Col>
                            <div className={"d-flex justify-content-between" + whiteText}>
                                <div className={"tableRowSecondTitle"}>
                                    {`$${deliveryRecord.Invoice?.invoiceNetPrice}`}
                                    &nbsp;
                                    <InvoiceBadge
                                        invoiceData={deliveryRecord.Invoice}
                                        badgeType="invoiceStatus"
                                        badgeClassName="statusBadgeSmall"
                                    />
                                </div>
                                <div className={"cardIconButton"}>

                                    <Badge color={"info"} className="badge-lg text-lg badge-circle">
                                        <i className="ni ni-ungroup"
                                            onClick={async (e) => {
                                                cloneInvoiceConfirmation(deliveryRecord.Invoice?.invoiceID);
                                            }} />
                                    </Badge>

                                    <span>&nbsp;</span>

                                    <Badge color={"info"} className="badge-lg text-lg badge-circle">
                                        <i className="ni ni-credit-card"
                                            onClick={async (e) => {
                                                props.setClickViewInvoiceButton(await getSingleInvoice(deliveryRecord.Invoice?.invoiceID));
                                            }} />
                                    </Badge>

                                    <span>&nbsp;</span>

                                    <Badge color={"info"} className="badge-lg text-lg badge-circle">
                                        <i className="ni ni-single-02"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.customerAction(deliveryRecord.Invoice?.Customer);
                                            }} />
                                    </Badge>

                                </div>
                            </div>
                            <div className={"tableRowSubTitle" + whiteText}>
                                {`${deliveryRecord.invoiceID}`}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            }

            {/* Currently no idea what to put in the footer */}
            {/* {showFullCard &&
                <CardFooter className={props.enableCardBgColor ? props.cardBgColor : ""}>

                </CardFooter>
            } */}
        </Card>
    ) : (
        <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
                <CardBody>
                    <strong className="text-info h1"><Trans>No Data Found</Trans></strong>
                    <h3 className="text-info"><Trans>Please Check Your Filter</Trans></h3>
                </CardBody>
            </Card>
        </Col>
    );
}

export default DeliveryRecordCard;

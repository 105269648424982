import React, { useCallback } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Badge,
    Collapse,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import CustomerActionModal from "../customer/CustomerActionModal";

import { Trans, useTranslation } from "react-i18next";
import DeliveryRecordScreenFilterModal from "../../../views/pages/deliveryRecord/DeliveryRecordScreenFilterModal";

import InvoiceCreateModal from "../invoice/InvoiceCreateModal"

import DeliveryRecordCard from "../../../views/pages/deliveryRecord/DeliveryRecordCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";

import DeliveryRecordEditModal from "../deliveryRecord/DeliveryRecordEditModal";

import { getDateString, getIntegerDecodeFromString, getRandomBgColor } from "../../../functions/Utility";

import DeliveryRecordSearchBar from "../components/DeliveryRecordSearchBar.js";
import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";
import DeliveryRecordMessageModal from "../deliveryRecord/DeliveryRecordMessageModal.js";
import moment from "moment";
import { DeliveryBasketListGlobal } from "context/DeliveryBasketContext";
import SpinnerOverlay from "../components/SpinnerOverlay";
import { setLocationType } from "react-geocode";
import InputTypeTextForm from "../components/InputTypeTextForm";
import { combineAddress } from "functions/Delivery";

function DeliveryBasketModal(props) {
    const { show, onHide } = props;
    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;

    // Context
    const { deliveryRecordGlobalList
        , isThisDeliveryRecordExist
        , addSingleDeliveryRecordToGlobalList
        , deleteSingleDeliveryRecordToGlobalList
        , returnCheckDeliveryRecordExistList
        , refreshSingleRecordFromDBInList
        , refreshAllRecordFromDBInList
        , moveUpSingleDeliveryRecordInGlobalList
        , moveDownSingleDeliveryRecordInGlobalList
        , deleteAllDeliveryRecordToGlobalList } = DeliveryBasketListGlobal();

    // data
    const [displayDeliveryRecordData, setDisplayDeliveryRecordData] = React.useState([]);

    const [refreshTable, setRefreshTable] = React.useState(0);

    // For deliveryRecordCard
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [selectedDeliveryRecodData, onSelectedDeliveryRecodData] = React.useState({});
    const [selectedCustomerData, setSelectedCustomerData] = React.useState({});

    // Data Transfer
    const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});
    const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

    // GUI
    const [isLoading, setIsLoading] = React.useState(false);
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [actionCopyDone, setActionCopyDone] = React.useState(false);

    // data control
    const [deliveryBasketMessage, setDeliveryBasketMessage] = React.useState('');

    // modal
    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);
    const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
    const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);


    React.useEffect(() => {
        if (refreshTable > 0) {
            // After calling this 
            refreshSingleRecordFromDBInList(selectedDeliveryRecodData.deliveryID);
        }
    }, [refreshTable]);


    React.useEffect(() => {
        let messageArrayToGet = [];
        let messageToGet = '';

        messageArrayToGet = displayDeliveryRecordData.map((item, index) => {
            let subitemText = item.DeliveryRecordSubitems.map((deliveryRecordSubitem) => {
                return `${deliveryRecordSubitem.deliverySubitemQuantity}${deliveryRecordSubitem?.Good?.goodUnit}${deliveryRecordSubitem?.Good?.goodNameShortForm}`
            }).join(', ');

            let deliveryMessageCustomContent = t('Address') + ': ' + combineAddress(item.DeliveryAddress)
                + '\n' + t('Date') + ': ' + getDateString(item.proposedDateTime, activeLang === "cn", false)
                + '\n' + t('Customer') + ': ' + item.Invoice.Customer.customerName
                + '\n' + t('Good') + ': ' + subitemText;
            return t('No.') + " " + (index + 1).toString() + " " + t('place') + '\n' + deliveryMessageCustomContent;
        });

        messageToGet = messageArrayToGet.join('\n\n');

        setDeliveryBasketMessage(messageToGet);
        setActionCopyDone(false);
    }, [displayDeliveryRecordData]);

    React.useEffect(() => {
        // Bypass the initial record
        // Use display data to do the work
        handleDisplayDeliveryRecordData(deliveryRecordGlobalList);
    }, [deliveryRecordGlobalList]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const handleDisplayDeliveryRecordData = (filteredSortedDeliveryRecordData) => {
        let listWithExistGlobalList = returnCheckDeliveryRecordExistList(filteredSortedDeliveryRecordData);
        setDisplayDeliveryRecordData(listWithExistGlobalList);
    }



    return (
        <Modal
            isOpen={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={props.onHide}
            autoFocus={true}
            backdrop={'static'}
            trapFocus={false}
            keyboard={false}
        >
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader className="" toggle={onHide} >
                <Row className="mt-1">
                    <Col>
                        <Trans>
                            Delivery basket
                        </Trans>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button  className="mr-1" color="primary" size="sm" onClick={async () => {
                            setIsLoading(true);
                            await refreshAllRecordFromDBInList();
                            setIsLoading(false);
                        }}>
                            <Trans>
                                Refresh
                            </Trans>
                        </Button>
                        <Button className="mr-1" color="danger" size="sm" onClick={async () => {
                            setIsLoading(true);
                            deleteAllDeliveryRecordToGlobalList();
                            setIsLoading(false);
                        }}>
                            <Trans>
                                Delete all
                            </Trans>
                        </Button>
                    </Col>

                </Row>

            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="deliveryBasketMessage"
                            inputName="deliveryBasketMessage"
                            inputId="deliveryBasketMessage"
                            inputPlaceholder={t("Multiple delivery message")}
                            type="textarea"
                            value={deliveryBasketMessage}
                            handleChange={(e) => { setDeliveryBasketMessage(e.target.value) }}
                        />
                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color={actionCopyDone ? "success" : "primary"}
                                onClick={() => {
                                    navigator.clipboard.writeText(deliveryBasketMessage);
                                    setActionCopyDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-1">
                    {displayDeliveryRecordData && Object.values(displayDeliveryRecordData).length >= 1 && displayDeliveryRecordData.map((deliveryItem, index) => (
                        <Col
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            data-id={deliveryItem.deliveryID}
                            key={deliveryItem.deliveryID}
                        >
                            <div className="ml-1">

                                <Badge color={"info"} className="mb-1 mr-1 badge-lg text-lg badge-circle">
                                    {index + 1}
                                </Badge>
                                <Button size="sm" color="primary" className="mb-1 mr-1"
                                    onClick={() => {
                                        moveUpSingleDeliveryRecordInGlobalList(deliveryItem, true);
                                    }}>
                                    <Trans>First</Trans>
                                </Button>
                                <Button size="sm" color="primary" className="mb-1 mr-1"
                                    onClick={() => {
                                        moveUpSingleDeliveryRecordInGlobalList(deliveryItem, false);
                                    }}>
                                    <i className="ni ni-bold-up" />
                                </Button>
                                <Button size="sm" color="primary" className="mb-1 mr-1"
                                    onClick={() => {
                                        moveDownSingleDeliveryRecordInGlobalList(deliveryItem, false);
                                    }}>
                                    <i className="ni ni-bold-down" />
                                </Button>
                                <Button size="sm" color="primary" className="mb-1"
                                    onClick={() => {
                                        moveDownSingleDeliveryRecordInGlobalList(deliveryItem, true);
                                    }}>
                                    <Trans>Last</Trans>
                                </Button>
                            </div>

                            <DeliveryRecordCard
                                showFullCard={showFullCard}
                                cardBgColor={""}
                                enableCardBgColor={false}
                                isSelectedCard={false}
                                deliveryRecordData={deliveryItem}
                                setClickViewInvoiceButton={(invoice) => {
                                    setSelectedInvoice(invoice);
                                    setModalInvoiceMessageIsOpen(true);
                                }}
                                onClickCard={(deliveryRecord) => {
                                    onSelectedDeliveryRecodData(deliveryRecord);
                                    setModalDeliveryRecordEditIsOpen(true);
                                }}
                                cloneInvoice={(invoice) => {
                                    setInvoiceSelectedDataForInvoiceClone(invoice);
                                    setModalInvoiceCreateIsOpen(true);
                                }}
                                customerAction={(customer) => {
                                    setSelectedCustomerData(customer);
                                    setModalCustomerActionIsOpen(true);
                                }}
                                cloneDeliveryRecord={(deliveryRecord) => {
                                    setDeliveryRecordSelectedDataForClone(deliveryRecord);
                                    setModalDeliveryRecordAddIsOpen(true);
                                }}
                                showDeliveryMessage={(deliveryRecord) => {
                                    onSelectedDeliveryRecodData(deliveryRecord);
                                    setModalDeliveryRecordMessageIsOpen(true);
                                }}
                                addOrDeleteDeliveryRecordToGlobalList={(deliveryRecordToAddOrDelete) => {

                                    if (isThisDeliveryRecordExist(deliveryRecordToAddOrDelete)) {
                                        deleteSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                    } else {
                                        addSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                    }
                                }}
                            />

                        </Col>
                    ))}
                    {displayDeliveryRecordData.length === 0 && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <strong className="text-info h1">
                                        {t("No Delivery Record Found!")}
                                    </strong>
                                    <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </ModalBody>

            <ModalFooter>

            </ModalFooter>




            <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={selectedCustomerData}
                refreshTable={refreshTableData}
            />
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={selectedInvoice}
            />
            <DeliveryRecordEditModal
                show={modalDeliveryRecordEditIsOpen}
                deliveryData={selectedDeliveryRecodData}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordEditIsOpen(false);
                }}
            />
            <InvoiceCreateModal
                invoiceToClone={invoiceSelectedDataForInvoiceClone}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                allowEmptySubitemList={true}
            />

            <DeliveryRecordCreateModal
                show={modalDeliveryRecordAddIsOpen}
                invoiceData={deliveryRecordSelectedDataForClone.Invoice}
                deliveryRecordToClone={deliveryRecordSelectedDataForClone}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordAddIsOpen(false);
                }}
            />

            <DeliveryRecordMessageModal
                show={modalDeliveryRecordMessageIsOpen}
                onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
                title={t("Delivery Message")}
                refreshTable={() => { }}
                deliveryRecordData={selectedDeliveryRecodData}
            />
        </Modal>
    );
}

export default DeliveryBasketModal;

import React from "react";
import { useHistory, useNavigate, navigate } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Badge,
  Spinner,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { getUserId, logout, updateLocalStorage } from "../../functions/Auth";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import ScrollToTop from "react-scroll-to-top";
import UserModalEditPassword from "views/pages/user/UserModalEditPassword";
import LocalSettingModal from "views/pages/localSetting/LocalSettingModal";
import SpinnerOverlay from "views/pages/components/SpinnerOverlay";
import InvoiceBadge from "views/pages/components/InvoiceBadge";
import { openPDFLink } from "functions/Utility";
import Notifications from "views/pages/components/Notifications";
import { isAccessible, getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import { DeliveryBasketListGlobal } from "context/DeliveryBasketContext";
import DeliveryBasketModal from "views/pages/deliveryBasket/DeliveryBasketModal";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, currentPathName, appPageRouteListObj }) {

  // GUI
  const [isLoading, setIsLoading] = React.useState(false);
  const [fontSize, setFontSize] = React.useState("");

  // Modal
  const [modalEditPasswordIsOpen, setModalEditPasswordIsOpen] = React.useState(false);
  const [modalLocalSettingIsOpen, setModalLocalSettingIsOpen] = React.useState(false);
  const [modalDeliveryBasketIsOpen, setModalDeliveryBasketIsOpen] = React.useState(false);

  // get data from local storage
  const [userJobList, setUserJobList] = React.useState([]);
  const [membershipCurrentCompanyList, setMembershipCurrentCompanyList] = React.useState([]);
  const [isSafeMode, setIsSafeMode] = React.useState(localStorage.getItem('safeMode') === 'On');

  // Context
  const { deliveryRecordGlobalList, deliveryRecordGlobalListCount } = DeliveryBasketListGlobal();


  //if mobile, default sidebar is closed
  useEffect(() => {

    if (isMobile) {
      toggleSidenav();
    }

    // for page refresh
    changeFontSize(localStorage.getItem("fontSize") === "null" ? "M" : localStorage.getItem("fontSize"));

    getUserJobDataFromLocalStorage();

    getMembershipCurrentCompanyDataFromLocalStorage();

  }, []);

  useEffect(() => {
    localStorage.setItem("safeMode", isSafeMode ? 'On' : 'Off');
  }, [isSafeMode]);

  const getUserJobDataFromLocalStorage = () => {
    // Get user job list from local storage 
    let dataFromLocalStorage = localStorage.getItem("userJobList");
    if (dataFromLocalStorage && dataFromLocalStorage != '') {
      setUserJobList(JSON.parse(dataFromLocalStorage));
    }
  }

  const getMembershipCurrentCompanyDataFromLocalStorage = () => {
    // Get user job list from local storage 
    let dataFromLocalStorage = localStorage.getItem("membershipCurrentCompanyList");

    if (dataFromLocalStorage && dataFromLocalStorage !== undefined && dataFromLocalStorage != '') {
      setMembershipCurrentCompanyList(JSON.parse(dataFromLocalStorage));
    }
  }

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;

  const routeChange = () => {
    // let path = `/work`;
    // history.push(path);

    window.location.reload();
  };

  function onLogoutClick(e) {
    e.preventDefault();
    logout();
    routeChange();
  }

  const changeLanguage = () => {
    if (activeLang === "en") {
      i18n.changeLanguage("cn");
    } else {
      i18n.changeLanguage("en");
    }
  };


  const changeFontSize = (fontSize) => {

    setFontSize(fontSize);

    localStorage.setItem("fontSize", fontSize);

    if (fontSize === "S") {
      document.documentElement.style.setProperty('--default-font-size', '14px');
    } else if (fontSize === "M") {
      document.documentElement.style.setProperty('--default-font-size', '16px');
    } else if (fontSize === "L") {
      document.documentElement.style.setProperty('--default-font-size', '18px');
    } else {
      document.documentElement.style.setProperty('--default-font-size', '16px');
    }
  };

  const getRountingHeaderHTML = () => {
    if (!appPageRouteListObj) return "";
    if (Object.values(appPageRouteListObj).length <= 0) return "";

    //routeItem[1] is the obj
    let routeItem = Object.entries(appPageRouteListObj).find((routeItem) => routeItem[1].path === currentPathName);
    if (!routeItem) return "";
    let routeObj = routeItem[1];
    return <div style={{ color: 'white' }}>
      <i style={{ color: 'white' }} className={routeObj.icon + '-lg'} />
      <span style={{ color: 'white' }} className="text-lg font-weight-bold">&nbsp;&nbsp;{t(routeObj.name)}</span>
    </div>

  }

  const openMobile = localStorage.getItem("openMobile");


  const onUpdateLocalStorage = async () => {
    setIsLoading(true);
    await updateLocalStorage(getUserId());
    getUserJobDataFromLocalStorage();
    setIsLoading(false);
  }


  return (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}
      <ScrollToTop smooth color="#5e72e4" />
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid  >
          <Collapse navbar isOpen={false}>
            {/*             <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
               <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup> 
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form> */}

            <Nav className="align-items-center ml-0" navbar>
              <NavItem className="d-xl-none" xs={8}>
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={() => {
                    toggleSidenav();
                  }}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>

            </Nav>
            <Container fluid
              className="pr-0 pl-0"
              onClick={() => {
                toggleSidenav();
              }}>
              <div className="align-items-center ml-auto mr-auto">
                {getRountingHeaderHTML()}
              </div>
            </Container>

            <Nav className="align-items-center mr-0" navbar>
              <div className=""  
              onClick={() => {
                setModalDeliveryBasketIsOpen(true);
              }}>
                <Media className="align-items-center">
                  <i style={{ color: 'white' }} className="fa fa-truck text-xl font-weight-bold" />
                  {<Badge color={deliveryRecordGlobalListCount > 0 ? "success" : "danger"} className="ml-1 badge-lg text-lg badge-circle">
                    {deliveryRecordGlobalListCount}
                  </Badge>}
                </Media>
              </div>
            </Nav>
            <Nav className="align-items-center mr-0" navbar>

              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0 cursorPointer" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={`${process.env.REACT_APP_FRONTEND_IMAGE_BASE_URL}/loginLogo`}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {localStorage.getItem("nickName")
                          ? localStorage.getItem("nickName")
                          : ""}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>

                  <DropdownItem className="noti-title" header tag="div">
                    <h6>{t("Welcome")} !</h6>
                  </DropdownItem>



                  <DropdownItem disabled>

                    <i className="ni ni-single-02" />
                    <span>
                      {localStorage.getItem("name")
                        ? localStorage.getItem("name")
                        : ""}
                    </span>

                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem disabled>
                    <i className="ni ni-briefcase-24" />
                    {userJobList && Array.isArray(userJobList) && userJobList.length >= 1 &&
                      userJobList.map((item, index) => {
                        return <Badge key={index} className="statusBadgeSmall" color="primary"><Trans>{item.job}</Trans></Badge>
                      })
                    }
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={async () => { await onUpdateLocalStorage(); }}>

                    <i className="ni ni-button-power" />
                    <span>
                      {t("Reload settings")}
                    </span>



                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={() => { setModalEditPasswordIsOpen(true); }}>
                    <i className="ni ni-settings" />
                    <span>
                      {t("Change password")}
                    </span>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={() => { setModalLocalSettingIsOpen(true); }}>
                    <i className="ni ni-settings" />
                    <span>
                      {t("Settings")}
                    </span>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={onLogoutClick}>
                    <i className="ni ni-user-run" />
                    <span>{t("Logout")}</span>
                  </DropdownItem>

                  <DropdownItem divider />

                  {isAccessible('controlSafeMode') &&
                    <>
                      <DropdownItem onClick={() => {
                        Notifications({
                          type: "generalNotice",
                          message: `${isSafeMode ? t('Safe mode is off') : t('Safe mode is on')}`
                        });
                        setIsSafeMode(!isSafeMode);
                      }}>
                        <i className="ni ni-lock-circle-open" />
                        <span>{t("Safe mode")}</span>
                        <span className="ml-1"><Badge className="statusBadgeSmall" color={isSafeMode ? 'success' : 'danger'}><Trans>{isSafeMode ? 'On' : 'Off'}</Trans></Badge></span>
                        <span>{getAccessibleTagForSafeMode('controlSafeMode')}</span>

                      </DropdownItem>

                      <DropdownItem divider />
                    </>
                  }
                  <DropdownItem onClick={() => { }}>

                    <i className="ni ni-planet" />
                    <span onClick={changeLanguage} style={{ fontSize: '1.2rem', cursor: "pointer" }}>

                      <span style={{ fontSize: activeLang === "en" ? "1.5rem" : "1.2rem", color: activeLang === "en" ? "#000" : "#CCC" }}>
                        EN
                      </span>
                      <span style={{ color: "#CCC" }}>&nbsp;|&nbsp;</span>
                      <span style={{ fontSize: activeLang === "cn" ? "1.5rem" : "1.2rem", color: activeLang === "cn" ? "#000" : "#CCC" }}>
                        中
                      </span>
                    </span>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem onClick={() => { }}>
                    <i className="ni ni-caps-small" />
                    <span style={{ cursor: "pointer" }}>
                      <span onClick={() => { changeFontSize("S") }} style={{ fontSize: fontSize === "S" ? "1.5rem" : "1.2rem", color: fontSize === "S" ? "#000" : "#CCC" }}>
                        {t("S")}
                      </span>
                      <span style={{ color: "#CCC" }}>&nbsp;|&nbsp;</span>
                      <span onClick={() => { changeFontSize("M") }} style={{ fontSize: fontSize === "M" ? "1.5rem" : "1.2rem", color: fontSize === "M" ? "#000" : "#CCC" }}>
                        {t("M")}
                      </span>
                      <span style={{ color: "#CCC" }}>&nbsp;|&nbsp;</span>
                      <span onClick={() => { changeFontSize("L") }} style={{ fontSize: fontSize === "L" ? "1.5rem" : "1.2rem", color: fontSize === "L" ? "#000" : "#CCC" }}>
                        {t("L")}
                      </span>
                    </span>
                  </DropdownItem>


                  {isAccessible('showSystemSubscription') &&
                    <>
                      <DropdownItem divider />
                      <DropdownItem >
                        <span>{getAccessibleTagForSafeMode('showSystemSubscription')}</span>
                        <div className="d-flex">
                          <div className="mr-3">
                            <i className="ni ni-credit-card" />
                          </div>
                          {membershipCurrentCompanyList && Array.isArray(membershipCurrentCompanyList) && membershipCurrentCompanyList.length >= 1 &&
                            membershipCurrentCompanyList.map((item, index) => {
                              return <div key={`${index}div`} className="flex-grow-1"
                                onClick={() => {
                                  openPDFLink(item.MembershipInvoiceSubitemMatch.InvoiceSubitem.Invoice.invoiceURL);
                                }}>
                                <div>
                                  <Badge key={`${index}type`} className="statusBadgeSmall" color="primary">
                                    <Trans>{item.MembershipType.membershipTypeName}</Trans>
                                  </Badge>
                                  <Badge key={`${index}price`} className="statusBadgeSmall" color="primary">
                                    ${item.MembershipInvoiceSubitemMatch.InvoiceSubitem.Invoice.paidAmount} / ${item.MembershipInvoiceSubitemMatch.InvoiceSubitem.unitPrice.toString()}
                                  </Badge>
                                  <InvoiceBadge
                                    key={`${index}invoiceStatus`}
                                    invoiceData={item.MembershipInvoiceSubitemMatch.InvoiceSubitem.Invoice}
                                    badgeType="invoiceStatus"
                                    badgeClassName="statusBadgeSmall"
                                  />

                                </div>
                                <div className="mt-1">
                                  <Badge key={`${index}2`} className="statusBadgeSmall" color="primary">

                                    {item.effectiveDate}{" "}<Trans>to</Trans>{" "}{item.expiryDate}

                                  </Badge>
                                </div>
                              </div>
                            })
                          }
                          {/* Case no effective membership found */}
                          {(!membershipCurrentCompanyList || !Array.isArray(membershipCurrentCompanyList) || membershipCurrentCompanyList.length <= 0) &&
                            <Badge className="statusBadgeSmall" color="danger"><Trans>No active membership</Trans></Badge>
                          }
                        </div>
                      </DropdownItem>
                    </>
                  }

                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <UserModalEditPassword
        show={modalEditPasswordIsOpen}
        onHide={() => setModalEditPasswordIsOpen(false)}
        title={t("Change Password")}
        button={t("Update")}
        userData={JSON.parse(localStorage.getItem("userData"))}
        refreshTable={() => { }}
      />
      <LocalSettingModal
        show={modalLocalSettingIsOpen}
        onHide={() => setModalLocalSettingIsOpen(false)}
        title={t("Setting")}
        userData={JSON.parse(localStorage.getItem("userData"))}
        refreshTable={() => { }}>

      </LocalSettingModal>
      <DeliveryBasketModal
        show={modalDeliveryBasketIsOpen}
        onHide={() => setModalDeliveryBasketIsOpen(false)}
        
      />

    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
